import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {useSetRecoilState} from "recoil";
import {newSiriusVeilDialogAtom} from "../../constants/atoms";
import {usePlan} from "../../hooks/usePlan";
import {useSiriusVeil} from "../../hooks/useSiriusVeil";
import {useMinMaxDates} from "../../hooks/useMinMaxDates";

type IProps = {
    id: string;
};

export const SiriusVeil: React.FC<IProps> = ({id}) => {
    const setDialog = useSetRecoilState(newSiriusVeilDialogAtom);
    const plan = usePlan();
    const [minDate, maxDate] = useMinMaxDates();
    const siriusVeil = useSiriusVeil(id);
    const [top, setTop] = useState<number>();
    const [bottom, setBottom] = useState<number>();
    const [left, setLeft] = useState<number>();
    const [right, setRight] = useState<number>();

    const startDateLabel = siriusVeil?.from.toLocaleDateString("fr-FR").slice(0, 5);
    const endDateLabel = siriusVeil?.to.toLocaleDateString("fr-FR").slice(0, 5);
    const titleLabel = siriusVeil?.title;

    const onClick = () => {
        if (!siriusVeil) return;
        setDialog({
            isOpened: true,
            id: siriusVeil.id,
            name: siriusVeil.title,
            from: siriusVeil.from,
            to: siriusVeil.to,
        })
    };

    useEffect(() => {
        if (!plan) return;
        if (!siriusVeil) return;
        const timelineHeader = document.getElementById("plan-content");
        const timelineBoundingBox = timelineHeader?.getBoundingClientRect();
        setTop(timelineBoundingBox?.top || 0);
        setBottom((window.innerHeight - (timelineBoundingBox?.bottom || 0)) || 0);

        const allTimelines = document.getElementsByClassName("timeline-box");
        if (allTimelines.length < 1) return;
        const firstTimeline = allTimelines[0];
        const firstTimelineBoundingBox = firstTimeline.getBoundingClientRect();

        const timeDifference = firstTimelineBoundingBox?.right - firstTimelineBoundingBox?.left;
        const timeAmplitude = maxDate.getTime() - minDate.getTime();
        const leftAmplitude = siriusVeil.from.getTime() - minDate.getTime();
        const rightAmplitude = maxDate.getTime() - siriusVeil.to.getTime();
        const leftOffset = timeDifference * (leftAmplitude / timeAmplitude);
        const rightOffset = timeDifference * (rightAmplitude / timeAmplitude);

        setLeft((firstTimelineBoundingBox?.left || 0) + leftOffset);
        setRight(((window.innerWidth - (firstTimelineBoundingBox?.right || 0)) || 0) + rightOffset);
    }, [plan, siriusVeil, minDate, maxDate]);

    if (!siriusVeil) return null;
    if (!top) return null;
    if (!bottom) return null;
    if (!left) return null;
    if (!right) return null;
    return (
        <Box onClick={onClick} sx={{
            position: "fixed",
            top: top,
            left: left,
            right: right,
            bottom: bottom,
            opacity: 0.8,
            background: `
            repeating-linear-gradient(
                -55deg,
                #F7F7F7,
                #F7F7F7 3px,
                #DDDDDD 3px,
                #DDDDDD 6px
            );
            `,
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "absolute",
                top: "-24px",
                width: "calc(100% + 40px)",
                left: "-20px",
                color: "#717171",
                fontSize: "12px",
            }}>
                <Box className={"sirius-veil-start-date-label"}>{startDateLabel}</Box>
                <Box className={"sirius-veil-title-label"} sx={{
                    margin: "0px 10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>{titleLabel}</Box>
                <Box className={"sirius-veil-end-date-label"}>{endDateLabel}</Box>
            </Box>
        </Box>
    )
};