import React, {FC} from "react";
import {Box} from "@mui/material";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {draggableTaskRefs, editTaskDialogAtom} from "../../constants/atoms";
import ArchiveIcon from "../icons/DragHandleIcon";
import {useDrag} from "react-dnd";
import {DropResult} from "../../constants/types";
import {usePlan} from "../../hooks/usePlan";
import {useReorderTasks} from "../../hooks/useReorderTasks";

type IProps = {
    id: string,
    hovered: boolean,
};

/**
 * **Responsibility**: TBC
 */
// TODO: Improve drag'n'drop
const TaskHandle: FC<IProps> = ({id, hovered}) => {
    const setEditTaskDialogState = useSetRecoilState(editTaskDialogAtom);
    const taskDraggableDiv = useRecoilValue(draggableTaskRefs(id));
    const plan = usePlan();
    const reorderTasks = useReorderTasks(plan?.id || "");
    const [, drag, dragPreview] = useDrag(() => ({
        type: "Task",
        item: {id},
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>();
            if (!dropResult) return;
            reorderTasks({
                dragged: id,
                after: dropResult.dropZoneId,
            });
        },
    }));
    dragPreview(taskDraggableDiv);
    return (
        <Box
            className={"task-handle"}
            ref={drag}
            sx={{
                display: "flex",
                opacity: hovered ? 1 : 0,
                justifyContent: "center",
                borderRadius: "10%"
            }}
            onClick={(event) => setEditTaskDialogState({
                isOpened: true,
                taskId: id,
                menuAnchorElement: event.currentTarget,
            })}>
            <ArchiveIcon/>
        </Box>
    );
};

export default TaskHandle;
