import React, {FC} from "react";
import ResponsivePlanComponent from "../presentational/ResponsivePlanComponent";
import {usePlan} from "../../hooks/usePlan";

type IProps = {};

/**
 * **Responsibility**: TBC
 */
const PlanIfExist: FC<IProps> = () => {
    const plan = usePlan();
    if (!plan) return null;
    if (!plan.id) return null;
    return <ResponsivePlanComponent/>;
};

export default PlanIfExist;
