import {People, Project} from "../constants/types";
import {FilterOptionsState} from "@mui/base/useAutocomplete/useAutocomplete";

export function filterProjectOptions(options: Project[], {inputValue}: FilterOptionsState<Project>) {
    if (!inputValue) return options;
    const normalizedInputValue = inputValue.toLowerCase().trim().replace(/[^a-zA-Z0-9\s]/g, "")
    if (normalizedInputValue === "") return options;
    const normalizedInputWords = normalizedInputValue.split(" ")
    return (
        options
            .filter(
                option => {
                    if (!option.name) return true;
                    const normalizedOptionName = option.name.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, "")
                    const normalizedOptionClientName = option.clientName?.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, "") ?? ""
                    const targetString = `${option.id}${normalizedOptionName}${normalizedOptionClientName}`
                    return (
                        normalizedInputWords.every(word => targetString.includes(word))
                    )
                }
            )
    )
}

export function filterOwnerOptions(options: People[], {inputValue}: FilterOptionsState<People>) {
    if (!inputValue) return options;
    const normalizedInputValue = inputValue.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, "")
    if (normalizedInputValue === "") return options;
    return (
        options
            .filter(
                option => {
                    if (!option.name && !option.email) return true;
                    const normalizedOptionName = option.name.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, "")
                    const normalizedOptionEmail = option.email.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, "")
                    return (
                        normalizedOptionName
                            .includes(normalizedInputValue)
                        || normalizedOptionEmail
                            .includes(normalizedInputValue)
                    )
                }
            )
    )
}