import React, {FC} from "react";
import {Box} from "@mui/material";
import {useGetTask} from "../../hooks/useGetTask";
import {Text} from "../presentational/Text";

type IProps = {
    id: string,
};

/**
 * **Responsibility**: TBC
 */
const TaskName: FC<IProps> = ({id}) => {
    const task = useGetTask(id);
    if (!task) return null;

    const {name} = task;
    return (
        <Box
            className={"taskname"}
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center"
            }}>
            <Text pTwoMedium>{name}</Text>
        </Box>
    );
};

export default TaskName;
