import React, {useEffect} from "react";
import {useCreatePlan} from "../../hooks/useCreatePlan";

// TODO: Move to SSR
export const NewPlanPage: React.FC = () => {
    const createPlan = useCreatePlan();

    useEffect(() => {
        createPlan().then((response) => {
            if (!response) return;
            window.location.href = `${window.location.origin}/${response.id}`;
        });
    }, [createPlan]);

    return (
        <>Creating you're new Suro...</>
    )
};
