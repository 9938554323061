import React from "react";
import {Button} from "@mui/material";

type IProps = {
    text: string,
    onClick: () => void,
    className?: string,
}

export const PrimaryButton: React.FC<IProps> = ({text, onClick, className}) => {
    return (
        <Button
            className={`primary-button ${className}`}
            onClick={onClick} sx={theme => ({
            textTransform: "none",
            backgroundColor: "#1072BD",
            color: "#ffffff",
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            borderRadius: theme.spacing(1),
            "&:hover": {
                backgroundColor: "#0E67AA"
            }
        })}>
            {text}
        </Button>
    )
};