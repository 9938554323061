import React from "react";
import {Button} from "@mui/material";

type IProps = {
    text: string,
    onClick: () => void,
    red?: boolean,
    className?: string,
}

export const SecondaryButton: React.FC<IProps> = ({className, text, onClick, red}) => {
    return (
        <Button className={className} onClick={onClick} sx={ theme=>({
            textDecoration: "underline",
            textTransform: "none",
            fontWeight: "bold",
            color: red ? "#D62638" : "#1072BD",
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            borderRadius: theme.spacing(1),
        })}>
            {text}
        </Button>
    )
};