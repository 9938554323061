import React, {FC} from "react";
import {Box} from "@mui/material";
import {animationQueueAtom, StatusType} from "../../constants/atoms";
import DoneIcon from "../icons/DoneIcon";
import Lottie from "lottie-react";
import confetti from "../../animations/confetti.json";
import {useRecoilState} from "recoil";

type IProps = {
    status: StatusType | null,
    highlighted: boolean,
    id?: string,
};

// TODO: Learn how to do animations
const DobbyComponent: FC<IProps> = ({status, highlighted, id}) => {
    const [animationQueue, setAnimationQueue] = useRecoilState(animationQueueAtom);

    if (!status) return null

    const backgroundColor = {
        "🟢": "#00A284",
        "🟠": "#F59E0B",
        "🔴": "#D62638",
        "✅": "#ffffff",
    }[status];

    const dobbyClass = {
        "🟢": "on-track",
        "🟠": "delayed-expected",
        "🔴": "help",
        "✅": "done",
    }[status];

    if (status === "✅") return (
        <Box>
            {id && animationQueue.has(id) && (
                <Box sx={{
                    position: "absolute",
                    pointerEvents: "none"
                }}>
                    <Lottie
                        style={{
                            height: "200px",
                            width: "200px",
                            transform: "translate(-45%, -45%)"
                        }}
                        onLoopComplete={() => setAnimationQueue(new Set(Array.from(animationQueue.values()).filter(item => item !== id)))}
                        loop
                        animationData={confetti}/>
                </Box>
            )}
            <DoneIcon className={`dobby-component ${dobbyClass}`}/>
        </Box>
    )

    const highlightedStyle = highlighted ? ({
        borderColor: "#1072BD",
        borderStyle: "solid",
    }) : ({});

    return (
        <Box className={`dobby-component ${dobbyClass}`} sx={{
            borderWidth: "0.2rem",
            width: "1.8rem",
            height: "1.8rem",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...highlightedStyle,
        }}>
            <Box sx={{
                width: "1.2rem",
                height: "1.2rem",
                borderRadius: "100%",
                backgroundColor: backgroundColor
            }}/>
        </Box>
    );
};

export default DobbyComponent;
