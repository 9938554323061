import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://e1cf5514239e47f0bcfa6d1ea917a913@o1303172.ingest.sentry.io/6541779",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}
