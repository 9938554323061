import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import {DateIcon} from "../icons/DateIcon";
import WithMarginHorizontalList from "./WithMarginHorizontalList";
import {InfoButton} from "../interactive/button/InfoButton";

type IProps = {};

/**
 * **Responsibility**: Display Footer Information
 */
const FooterComponent: FC<IProps> = () => {
    return (
        <Box id="footer" sx={theme => ({
            display: "flex",
            justifyContent: "space-between",
            marginX: theme.spacing(2),
        })}>
            <WithMarginHorizontalList>
                <DateIcon whiteBackground type={"Scheduled"}/>
                <Typography variant={"h4"}>Scheduled</Typography>
                <DateIcon whiteBackground type={"DoneOnTime"}/>
                <Typography variant={"h4"}>Done On Time</Typography>
                <DateIcon whiteBackground type={"ExpectedLate"}/>
                <Typography variant={"h4"}>Expected Late</Typography>
                <DateIcon whiteBackground type={"DoneDelayed"}/>
                <Typography variant={"h4"}>Done Delayed</Typography>
            </WithMarginHorizontalList>
            <InfoButton/>
        </Box>
    );
};

export default FooterComponent;
