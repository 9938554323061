import React, {FC, useRef} from "react";
import {Box} from "@mui/material";
import {EditableTextField} from "../interactive/fields/EditableTextField";
import {useUpdateTask} from "../../hooks/useUpdateTask";
import {useTask} from "../../hooks/useTask";

type IProps = {
    id: string,
};

/**
 * **Responsibility**: TBC
 */
const TaskLearnings: FC<IProps> = ({id}) => {
    const boxRef = useRef<HTMLDivElement>(null);
    const updateTask = useUpdateTask();
    const task = useTask(id);

    if (!task) return null;
    return (
        <Box ref={boxRef}
             className={"task-learnings"}
             sx={theme => ({
                 minHeight: "100%",
                 width: "100%",
                 paddingLeft: theme.spacing(2),
                 paddingRight: theme.spacing(2),
                 display: "flex",
                 alignItems: "center",
             })}>
            <EditableTextField
                variant={"h3"}
                value={task.risks}
                allowEmpty
                onChange={value => updateTask({
                    ...task,
                    risks: value,
                })}
                multiline
                deportedClickBox={boxRef}
                sx={{
                    overflowWrap: "anywhere"
                }}
            />
        </Box>
    );
};

export default TaskLearnings;
