import React, {useRef, useState} from "react";
import Box from "@mui/material/Box";
import {useSetRecoilState} from "recoil";
import {Menu} from "./Menu";
import {MenuItem} from "@mui/material";
import {editMilestoneDialogAtom, newSiriusVeilDialogAtom, newTaskDialogAtom} from "../../../constants/atoms";
import {usePlan} from "../../../hooks/usePlan";
import {Text} from "../../presentational/Text";
import {AddIcon} from "../../icons/AddIcon";
import {SBox} from "../../presentational/SBox";

// TODO: Improve dialog states
export const AddMenu: React.FC = () => {
    const ref = useRef();
    const [open, setOpen] = useState<boolean>(false);
    const setAddMilestoneDialogState = useSetRecoilState(editMilestoneDialogAtom);
    const setAddSiriusVeilDialogState = useSetRecoilState(newSiriusVeilDialogAtom);
    const setNewTaskDialogState = useSetRecoilState(newTaskDialogAtom);
    const plan = usePlan();
    if (!plan) return null;
    const onAddTaskClick = () => {
        setNewTaskDialogState({
            isOpened: true,
        });
        setOpen(false);
    };
    const onAddMilestoneClick = () => setAddMilestoneDialogState({isOpened: true, planId: plan.id});
    const onAddSiriusVeil = () => setAddSiriusVeilDialogState({isOpened: true});
    return (
        <>
            <Box
                id={"add-menu-button"} ref={ref} onClick={() => setOpen(true)}
            >
                <SBox
                    sx={theme => ({
                        gap:theme.spacing(0.5),
                        cursor: 'pointer',
                        backgroundColor: open ? "#f7f7f7" : "white",
                    })}
                    bordered
                    padding={[0.5, 1, 0.5, 1]}
                    margin={[0, 0, 0, 2]}
                    flex
                    aligned>
                    <AddIcon/>
                    <Text buttonSecondaryMedium>
                        Add
                    </Text>
                </SBox>
            </Box>
            <Menu
                id={"add-menu"}
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={ref.current}>
                <MenuItem id={"add-deliverable-button-line"} onClick={onAddTaskClick}>
                    <Text>
                        Add a deliverable
                    </Text>
                </MenuItem>
                <MenuItem id={"add-milestone-button-line"} onClick={onAddMilestoneClick}>
                    <Text>
                        Add a milestone
                    </Text>
                </MenuItem>
                <MenuItem id={"add-sirius-veils-button-line"} onClick={onAddSiriusVeil}>
                    <Text>
                        Add a highlighted period
                    </Text>
                </MenuItem>
            </Menu>
        </>
    )
};
