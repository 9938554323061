import {DataFromAPI} from "./useGetDataFromAPI";
import {Milestone, MilestoneType, People, Plan, Project, SiriusVeil, Task} from "../constants/types";
import {StatusType} from "../constants/atoms";

export type ProcessedData = {
    tasks: Array<Task>,
    milestones: Array<Milestone>,
    siriusVeils: Array<SiriusVeil>,
    plan: Plan,
    peoples: People[],
    projects: Project[],
    groupPMS: String[],
};

const processMinMaxDate = (data: DataFromAPI | undefined): { minDate: Date, maxDate: Date } => {
    if (!data) return {
        minDate: new Date(),
        maxDate: new Date(),
    };

    const allDates = [
        ...([] as Array<string>).concat(...data.tasks.map(task => [
            task.startDate,
            task.scheduledDate,
            task.delayedDate,
        ])),
        ...([] as Array<string>).concat(...data.siriusVeils.map(siriusVeil => [
            siriusVeil.from,
            siriusVeil.to,
        ])),
        ...data.milestones.map(milestone => milestone.date),
    ]
        .filter(dateOrNull => dateOrNull !== null)
        .sort();
    if (allDates.length < 1) return {
        minDate: new Date(),
        maxDate: new Date(),
    }
    const minDate = new Date(allDates[0]);
    const maxDate = new Date(allDates[allDates.length - 1]);
    const dateDiffInMillis = maxDate.getTime() - minDate.getTime();
    minDate.setTime(minDate.getTime() - 0.05 * dateDiffInMillis);
    maxDate.setTime(maxDate.getTime() + 0.05 * dateDiffInMillis);
    return {
        minDate,
        maxDate,
    }
};

// TODO: Find a more elegant way to process back-end data. This has become uncontrollable
export const processData = (data: DataFromAPI | undefined): ProcessedData | undefined => {
    const milestones: Milestone[] = data?.milestones.map(milestone => ({
        id: milestone.id,
        date: new Date(milestone.date + "T00:00:00"),
        name: milestone.name,
        type: milestone.type as MilestoneType,
    })) || [];
    const tasks: Task[] = data?.tasks.map(task => ({
        id: task.id,
        status: task.status as StatusType,
        delayedDate: task.delayedDate ? new Date(task.delayedDate + "T00:00:00.000Z") : null,
        startDate: task.startDate ? new Date(task.startDate + "T00:00:00.000Z") : null,
        scheduledDate: task.scheduledDate ? new Date(task.scheduledDate + "T00:00:00.000Z") : null,
        name: task.name,
        owner: task.owner,
        statusComment: task.statusComment,
        link: task.link,
        risks: task.risks,
        index: task.index,
        customerMilestone: task.customerMilestone,
        projects: task.projects,
    })) || [];
    const siriusVeils: SiriusVeil[] = data?.siriusVeils.map(siriusVeil => ({
        id: siriusVeil.id,
        from: new Date(siriusVeil.from + "T00:00:00"),
        to: new Date(siriusVeil.to + "T00:00:00"),
        title: siriusVeil.title,
    })) || [];
    const plan: Plan = data?.plan ? {
        id: data.plan.id,
        name: data.plan.name,
        mainSuro: data.plan.mainSuro || false,
        owner: data.plan.owner,
        tasks: data.tasks.map(task => task.id),
        milestones: data.milestones.map(milestone => milestone.id),
        siriusVeils: data.siriusVeils.map(siriusVeil => siriusVeil.id),
        ...processMinMaxDate(data),
    } : {
        id: "",
        name: "",
        mainSuro: false,
        minDate: new Date(),
        maxDate: new Date(),
        owner: null,
        tasks: [],
        milestones: [],
        siriusVeils: [],
    };
    return {
        tasks,
        milestones,
        siriusVeils,
        plan,
        peoples: [],
        projects: [],
        groupPMS: []
    };
};
