import React from "react";
import {SBox} from "./SBox";
import {Text} from "./Text";
import {ToggleOnIcon} from "../icons/ToggleOnIcon";
import {ToggleOffIcon} from "../icons/ToggleOffIcon";

type IProps = {
    title: string,
    subTitle: string,
    value: boolean,
    onClick: () => void,
    first?: boolean,
};

export const SortingMenuLine: React.FC<IProps> = (
    {
        title,
        value,
        subTitle,
        onClick,
        first,
    }
) => {
    return (
        <SBox flex onClick={onClick} sx={theme => first ? ({}) : ({
            paddingTop: theme.spacing(3)
        })}>
            <SBox flex grow column>
                <Text buttonPrimaryRegular secondaryFiveHundred>
                    {title}
                </Text>
                <Text pOneRegular secondaryFourHundred>
                    {subTitle}
                </Text>
            </SBox>
            <SBox flex aligned margin={[0, 0, 0, 3]}>
                {value
                    ? (<ToggleOnIcon/>)
                    : (<ToggleOffIcon/>)}
            </SBox>
        </SBox>
    )
};