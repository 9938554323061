import React, {FC} from "react";
import {editMilestoneDialogAtom} from "../../../constants/atoms";
import {useRecoilState} from "recoil";
import {MilestoneType} from "../../../constants/types";
import {Dialog} from "./Dialog";
import {SBox} from "../../presentational/SBox";
import {Text} from "../../presentational/Text";
import {MilestoneTypeField} from "../fields/MilestoneTypeField";
import {DateField} from "../fields/DateField";
import {TextField} from "../fields/TextField";
import {useUpdateMilestone} from "../../../hooks/useUpdateMilestone";
import {useDeleteMilestone} from "../../../hooks/useDeleteMilestone";
import {useCreateMilestone} from "../../../hooks/useCreateMilestone";

// TODO : Make sure there is always one and only one dialog for creation/edition

export const EditMilestoneDialog: FC = () => {
    const [dialog, setDialogState] = useRecoilState(editMilestoneDialogAtom);
    const updateMilestone = useUpdateMilestone();
    const deleteMilestone = useDeleteMilestone();
    const createMilestone = useCreateMilestone();
    const onEdit = () => {
        if (!dialog.date) return;
        if (!dialog.name) return;
        if (!dialog.id) {
            createMilestone({
                id: "",
                date: dialog.date,
                type: dialog.milestoneType || "Default",
                name: dialog.name,
            })
        } else {
            updateMilestone({
                id: dialog.id,
                date: dialog.date,
                type: dialog.milestoneType || "Default",
                name: dialog.name,
            })
        }
        setDialogState({isOpened: false});
    };
    const onDelete = () => {
        if (!dialog.id) return;
        deleteMilestone(dialog.id);
        setDialogState({isOpened: false});
    };
    const onClose = () => setDialogState({isOpened: false});
    const onTitleChange = (title: string) => setDialogState({
        ...dialog,
        name: title,
    });
    const onDateChange = (date: Date | null) => setDialogState({
        ...dialog,
        date: date || undefined,
    });
    const onTypeChange = (type: MilestoneType) => setDialogState({
        ...dialog,
        milestoneType: type,
    });
    return (
        <Dialog
            onSave={onEdit}
            close={onClose}
            onDelete={dialog.id ? onDelete : undefined}
            open={dialog.isOpened}
            title={"Milestone"}>
            <SBox padding={[3, 3, 3, 3]}>
                <SBox flex>
                    <Text>Title</Text>
                </SBox>
                <SBox bordered flex fullWidth column>
                    <TextField id={"edit-milestone-name"} value={dialog.name || ""}
                               onChange={onTitleChange}/>
                </SBox>
                <SBox flex>
                    <Text grey>
                        We recommend to use a short title (around 15 characters).
                    </Text>
                </SBox>
                <SBox flex margin={[2, 0, 0, 0]}>
                    <Text>Type</Text>
                </SBox>
                <SBox bordered flex fullWidth column>
                    <MilestoneTypeField
                        value={dialog.milestoneType || "Default"}
                        onChange={onTypeChange}/>
                </SBox>
                <SBox flex margin={[2, 0, 0, 0]}>
                    <Text>
                        Date
                    </Text>
                </SBox>
                <SBox bordered flex fullWidth column>
                    <DateField
                        value={dialog.date || null}
                        onChange={onDateChange}/>
                </SBox>
            </SBox>
        </Dialog>
    )
};
