import React from "react";
import {TextField as MuiTextField} from "@mui/material";

type IProps = {
    value: string,
    onChange: (value: string) => void,
    lines?: number,
    id?: string,
};

export const TextField: React.FC<IProps> = ({value, onChange, lines, id}) => {
    return (
        <MuiTextField
            id={id}
            value={value}
            onChange={event => onChange(event.target.value)}
            multiline={!!lines}
            minRows={lines || 0}
            maxRows={4}
            sx={{
                "& input": {
                    padding: 0,
                },
                "& div": {
                    padding: 0,
                },
                "& fieldset": {
                    border: "none",
                    padding: 0,
                }
            }}
        />
    )
};