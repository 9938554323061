// noinspection SpellCheckingInspection

import React, {FC} from "react";
import {Box, SvgIcon} from "@mui/material";

type IProps = {};

/**
 * **Responsibility**: TBC
 */
const ArrowOutIcon: FC<IProps> = () => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <SvgIcon width="16" height={"16"} viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.25 2.49993C9.25004 2.22379 9.47392 1.99996 9.75007 2L13.4996 2.0005C13.7757 2.00054 13.9995 2.22434 13.9995 2.50043L14 6.24993C14 6.52608 13.7762 6.74996 13.5001 6.75C13.2239 6.75004 13 6.52621 13 6.25007L12.9996 3.00043L9.74993 3C9.47379 2.99996 9.24996 2.77608 9.25 2.49993Z"
                      fill="#717171"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.8518 2.1484C14.0471 2.34366 14.0471 2.66024 13.8518 2.85551L9.35184 7.35551C9.15658 7.55077 8.84 7.55077 8.64474 7.35551C8.44948 7.16024 8.44948 6.84366 8.64474 6.6484L13.1447 2.1484C13.34 1.95314 13.6566 1.95314 13.8518 2.1484Z"
                      fill="#717171"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H7C7.27614 4 7.5 4.22386 7.5 4.5C7.5 4.77614 7.27614 5 7 5L3 5L3 13H11V9C11 8.72386 11.2239 8.5 11.5 8.5C11.7761 8.5 12 8.72386 12 9V13C12 13.2652 11.8946 13.5196 11.7071 13.7071C11.5196 13.8946 11.2652 14 11 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5C2 4.73478 2.10536 4.48043 2.29289 4.29289Z"
                      fill="#717171"/>
            </SvgIcon>
        </Box>
    );
};

export default ArrowOutIcon;
