import React, {FC} from "react";
import {Box} from "@mui/material";
import {EditMilestoneDialog} from "../interactive/dialog/EditMilestoneDialog";
import {PlanOwnerDialog} from "../interactive/dialog/PlanOwnerDialog";
import TaskMenu from "../interactive/menu/TaskMenu";
import {SiriusVeilDialog} from "../interactive/dialog/SiriusVeilDialog";
import {DeliverableDialog} from "../interactive/dialog/DeliverableDialog";

type IProps = {
    children: React.ReactNode;
};

/**
 * **Responsibility**: TBC
 */
const WithDialogs: FC<IProps> = ({children}) => {
    return (
        <Box>
            <EditMilestoneDialog/>
            <PlanOwnerDialog/>
            <TaskMenu/>
            <SiriusVeilDialog/>
            <DeliverableDialog/>
            {children}
        </Box>
    );
};

export default WithDialogs;
