import React, {CSSProperties} from "react";
import {Player} from "@lottiefiles/react-lottie-player";
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";

const LOADER_LOTTIE_FILE = "https://assets3.lottiefiles.com/packages/lf20_o8zsifcp.json";

const playerStyles: CSSProperties = {
    height: '300px',
    width: '300px'
};

const loaderSx: SxProps = {
    height: "100vh",
    width: "100vw",
    backgroundColor: "lightgrey",
    opacity: 0.9,
    position: "fixed",
    zIndex: 10000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

type IProps = {
    loading?: boolean,
};

export const Loader: React.FC<IProps> = ({loading}) => {
    if (!loading) return null;
    return (
        <Box sx={loaderSx}>
            <Player
                autoplay
                loop
                src={LOADER_LOTTIE_FILE}
                style={playerStyles}
            />
        </Box>
    )
};
