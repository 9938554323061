import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import {Timeline} from "../domain/Timeline";

type IProps = {};

/**
 * **Responsibility**: TBC
 */
const ColumnTitleComponent: FC<IProps> = () => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
        }}>
            <Typography
                align={"left"}
                variant={"h3"}
                sx={theme => ({
                    paddingLeft: theme.spacing(3),
                    fontWeight: "bold",
                    color: "#717171",
                    flexGrow: 1,
                    flexBasis: "25%",
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                })}>
                DELIVERABLES
            </Typography>
            <Typography
                align={"center"}
                variant={"h3"}
                sx={{
                    fontWeight: "bold",
                    color: "#717171",
                    flexGrow: 1,
                    flexBasis: "5%"
                }}>
                {/*STATUS*/}
            </Typography>
            <Typography
                id={"owner-column-label"}
                align={"center"}
                variant={"h3"}
                sx={{
                    fontWeight: "bold",
                    color: "#717171",
                    flexGrow: 1,
                    flexBasis: "7%"
                }}>
                {/*OWNER*/}
            </Typography>
            <Box
                sx={{
                    flexGrow: 1,
                    flexBasis: "43%",
                    display: "flex",
                    alignItems: "flex-end",
                }}
                id={"timeline-header-column"}
            >
                <Timeline/>
            </Box>
            <Typography
                id={"learnings-column-label"}
                align={"left"}
                variant={"h3"}
                sx={theme => ({
                    fontWeight: "bold",
                    color: "#717171",
                    flexGrow: 1,
                    flexBasis: "20%",
                    marginTop: theme.spacing(2),
                    paddingRight: theme.spacing(3),
                    paddingLeft: theme.spacing(1),
                })}>
                LEARNINGS
            </Typography>
        </Box>
    );
};

export default ColumnTitleComponent;

