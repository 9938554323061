import React from "react";
import Box from "@mui/material/Box";
import {Theme} from "@mui/material";
import {SystemStyleObject} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {SxProps} from "@mui/system";

type IProps = {
    children: React.ReactNode,
    bordered?: boolean,
    sx?: (theme: Theme) => SystemStyleObject<Theme>,
    padding?: number[],
    margin?: number[],
    flex?: boolean,
    absolute?: boolean,
    grow?: boolean,
    basis?: string,
    spaceBetween?: boolean,
    onClick?: () => void,
    aligned?: boolean,
    fullWidth?: boolean,
    column?: boolean,
    className?: string,
};


// TODO: Mistake
export const SBox: React.FC<IProps> = (
    {
        className,
        children,
        bordered,
        sx,
        padding,
        margin,
        flex,
        absolute,
        grow,
        basis,
        spaceBetween,
        onClick,
        aligned,
        fullWidth,
        column
    }
) => {
    let absoluteStyle: SxProps = absolute ? ({
        position: "absolute",
    }) : ({});
    let flexStyle: SxProps = (flex || grow || basis || spaceBetween || aligned) ? ({
        display: "flex",
        flexGrow: grow ? 1 : null,
        flexBasis: basis ? basis : null,
        justifyContent: spaceBetween ? "space-between" : null,
        alignItems: aligned ? "center" : null,
        flexDirection: column ? "column" : "row",
    }) : ({});
    const borderedStyle = (theme: Theme) => bordered ? ({
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1.5),
        borderStyle: "solid",
        borderColor: "#B0B0B0",
    }) : ({});
    let marginStyle = (theme: Theme) => ({});
    if (margin) {
        const [top, right, bottom, left] = margin;
        marginStyle = (theme: Theme) => margin ? ({
            marginTop: theme.spacing(top),
            marginRight: theme.spacing(right),
            marginBottom: theme.spacing(bottom),
            marginLeft: theme.spacing(left),
        }) : ({});
    }
    let paddingStyle = (theme: Theme) => ({});
    if (padding) {
        const [top, right, bottom, left] = padding;
        paddingStyle = (theme: Theme) => padding ? ({
            paddingTop: theme.spacing(top),
            paddingRight: theme.spacing(right),
            paddingBottom: theme.spacing(bottom),
            paddingLeft: theme.spacing(left),
        }) : ({});
    }
    let widthStyle = (theme: Theme) => ({});
    if (fullWidth) {
        widthStyle = (theme: Theme) => fullWidth ? ({
            width: "100%",
        }) : ({});
    }
    const customStyle = (theme: Theme) => ({
        ...borderedStyle(theme),
        ...paddingStyle(theme),
        ...marginStyle(theme),
        ...widthStyle(theme),
        ...flexStyle,
        ...absoluteStyle,
    });
    return (
        <Box
            className={className}
            onClick={onClick}
            sx={theme => sx ? ({
                ...customStyle(theme),
                ...sx(theme),
            }) : ({
                ...customStyle(theme),
            })}>
            {children}
        </Box>
    )
};
