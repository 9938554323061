import React from "react";
import {SvgIcon} from "@mui/material";

// TODO: Avoid creating component for this. I should be able to import SVG directly from .svg files
export const AddIcon: React.FC = () => {
    return (
        <SvgIcon
            sx={{width: "14px", height: "14px"}}
            width="16" height="16"
            viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6665 7.33329H8.6665V3.33329C8.6665 3.15648 8.59627 2.98691 8.47124 2.86189C8.34622 2.73686 8.17665 2.66663 7.99984 2.66663C7.82303 2.66663 7.65346 2.73686 7.52843 2.86189C7.40341 2.98691 7.33317 3.15648 7.33317 3.33329V7.33329H3.33317C3.15636 7.33329 2.98679 7.40353 2.86177 7.52855C2.73674 7.65358 2.6665 7.82315 2.6665 7.99996C2.6665 8.17677 2.73674 8.34634 2.86177 8.47136C2.98679 8.59639 3.15636 8.66663 3.33317 8.66663H7.33317V12.6666C7.33317 12.8434 7.40341 13.013 7.52843 13.138C7.65346 13.2631 7.82303 13.3333 7.99984 13.3333C8.17665 13.3333 8.34622 13.2631 8.47124 13.138C8.59627 13.013 8.6665 12.8434 8.6665 12.6666V8.66663H12.6665C12.8433 8.66663 13.0129 8.59639 13.1379 8.47136C13.2629 8.34634 13.3332 8.17677 13.3332 7.99996C13.3332 7.82315 13.2629 7.65358 13.1379 7.52855C13.0129 7.40353 12.8433 7.33329 12.6665 7.33329Z"
                fill="#222222"/>
        </SvgIcon>
    )
}


