import React, {FC} from "react";
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";

type IProps = {
    header: JSX.Element,
    footer: JSX.Element,
    content: JSX.Element,
};

const flexGrowBoxSx: SxProps = {
    flexGrow: 1,
    overflowY: "scroll",
    overflowX: "hidden",
};

/**
 * **Responsibility**: TBC
 */
const ContentWithHeaderFooterComponent: FC<IProps> = (
    {header, footer, content}
) => {
    const zoomFactor = (window.innerWidth > 3800) ? 2 : 1;
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            height: `calc(100vh / ${zoomFactor})`,
            boxSizing: "border-box",
        }}>
            <Box sx={{marginBottom: "-1px"}}>{header}</Box>
            <Box id={"plan-content"} sx={flexGrowBoxSx}>{content}</Box>
            <Box>{footer}</Box>
        </Box>
    );
};

export default ContentWithHeaderFooterComponent;
