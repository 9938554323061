import React from "react";
import {Route, Routes as ReactRouterRoutes} from "react-router-dom";
import PlanPage from "../pages/PlanPage";
import {NewPlanPage} from "../pages/NewPlanPage";

export const Routes: React.FC = () => {
    return (
        <ReactRouterRoutes>
            <Route path={`/:id`} element={<PlanPage/>}/>
            <Route path={'/'} element={<NewPlanPage/>}/>
        </ReactRouterRoutes>
    )
};