import {Milestone} from "../constants/types";
import {useMutation} from "react-query";
import {makePatchRequest} from "../utils/http";
import {getEditMilestoneApiUrl} from "../utils/urls";
import {queryClient} from "../utils/query-client";
import {formatDate} from "../utils/dates";

export const useUpdateMilestone = (): (milestone: Milestone) => void => {
    const mutation = useMutation({
        mutationFn: async (milestone: Milestone) => {
            return makePatchRequest({
                url: getEditMilestoneApiUrl(milestone.id),
                body: {
                    name: milestone.name,
                    date: formatDate(milestone.date),
                    type: milestone.type,
                }
            })
                .catch(console.error)
        },
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["data"]})
    });

    return (milestone: Milestone) => {
        mutation.mutate(milestone);
    };
};