import {usePlan} from "./usePlan";
import {useMutation} from "react-query";
import {makePostRequest} from "../utils/http";
import {getCreateTaskApiUrl} from "../utils/urls";
import {queryClient} from "../utils/query-client";
import {StatusType} from "../constants/atoms";
import {People, Project} from "../constants/types";
import {formatDate} from "../utils/dates";

export const useCreateTask = () => {
    const plan = usePlan();
    const mutation = useMutation(
        {
            mutationFn: async ({
                                   name,
                                   status,
                                   teamsPlan,
                                   owner,
                                   link,
                                   startDate,
                                   scheduledDate,
                                   delayedDate,
                                   projects,
                                   customerMilestone
                               }: {
                name: string | undefined,
                status: StatusType | undefined,
                teamsPlan: string | undefined,
                owner: People | null | undefined,
                link: string | undefined,
                startDate: Date | null | undefined,
                scheduledDate: Date | null | undefined,
                delayedDate: Date | null | undefined,
                projects: Project[] | undefined,
                customerMilestone: boolean | undefined,
            }) => {
                if (!plan) return;
                await makePostRequest({
                    url: getCreateTaskApiUrl(),
                    body: {
                        planId: plan.id,
                        name,
                        status,
                        teamsPlan,
                        owner: owner?.email,
                        link,
                        startDate: formatDate(startDate || null),
                        scheduledDate: formatDate(scheduledDate || null),
                        delayedDate: formatDate(delayedDate || null),
                        projects,
                        customerMilestone
                    },
                })
                    .catch(console.error)
            },
            onSuccess: () => queryClient.invalidateQueries({
                queryKey: ["data"]
            }),
        }
    );
    return mutation.mutate;
};