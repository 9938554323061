import React, {FC} from "react";
import {Box} from "@mui/material";
import {useDrop} from "react-dnd";
import {DragObject, DropResult} from "../../../constants/types";
import {useSetRecoilState} from "recoil";
import {draggableTaskRefs} from "../../../constants/atoms";

type IProps = {
    children: React.ReactNode[],
    id: string
};


/**
 * **Responsibility**: TBC
 */
const DraggableTask: FC<IProps> = ({children, id}) => {
    const setDraggableTaskDiv = useSetRecoilState(draggableTaskRefs(id));
    const [{isOver}, drop] = useDrop<DragObject, DropResult, { isOver: boolean }>(() => ({
        accept: "Task",
        collect: (monitor) => ({
            isOver: monitor.isOver()
        }),
        drop: (item) => ({
            dropZoneId: id,
            dragObjectId: item.id,
        }),
    }));
    return (
        <Box ref={(element: HTMLDivElement) => {
            drop(element);
            setDraggableTaskDiv(element);
        }} sx={{
            height: "100%",
            borderBottom: isOver ? "solid #1072BD 3px" : null,
        }}>
            {children}
        </Box>
    );
};

export default DraggableTask;
