import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import {useGetTask} from "../../hooks/useGetTask";
import PeoplePictureComponent from "../presentational/PeoplePictureComponent";

type IProps = {
    id: string,
};

/**
 * **Responsibility**: TBC
 */
const TaskOwner: FC<IProps> = ({id}) => {
    const task = useGetTask(id);
    if (!task) return null;
    return (
        <Box
            className={"task-owner"}
            sx={{
                width: "100%",
                minHeight: "2.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            {!!task.owner && (
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <PeoplePictureComponent people={task.owner}/>
                    {!task.owner.imgSrc && (
                        <Typography
                            variant={"h3"}
                            whiteSpace={"pre-line"}
                            align={"center"}
                        >
                            {task.owner.name}
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default TaskOwner;
