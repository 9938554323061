import React from "react";
import Box from "@mui/material/Box";
import {ToastContainer} from "react-toastify";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {QueryClientProvider} from "react-query";
import {queryClient} from "../../utils/query-client";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {RecoilRoot} from "recoil";
import {BrowserRouter} from "react-router-dom";

type IProps = {
    children: React.ReactNode,
};

export const WithProviders: React.FC<IProps> = ({children}) => {
    return (
        <Box>
            <ToastContainer/>
            <DndProvider backend={HTML5Backend}>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <RecoilRoot>
                            <BrowserRouter>
                                {children}
                            </BrowserRouter>
                        </RecoilRoot>
                    </LocalizationProvider>
                </QueryClientProvider>
            </DndProvider>
        </Box>
    )
};