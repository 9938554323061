import {useMutation} from "react-query";
import {makePostRequest} from "../utils/http";
import {getCreatePlanApiUrl} from "../utils/urls";


export type CreatePageApiResponse = {
    id: string,
}

export const useCreatePlan = () => {
    const mutation = useMutation(
        {
            mutationFn: async () => makePostRequest<{}, CreatePageApiResponse>({
                url: getCreatePlanApiUrl(),
                body: {},
            })
                .catch(console.error)
        }
    );
    return mutation.mutateAsync;
};