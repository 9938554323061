import React, {FC} from "react";
import {Box} from "@mui/material";
import {useSetRecoilState} from "recoil";
import {editOwnerDialogAtom} from "../../../constants/atoms";
import {usePlan} from "../../../hooks/usePlan";

type IProps = {
    children: React.ReactNode,
};

/**
 * **Responsibility**: Display Plan Owner Dialog on click of this component
 */
const PlanOwnerDialogClickListener: FC<IProps> = ({children}) => {
    const plan = usePlan();
    const setDialogState = useSetRecoilState(editOwnerDialogAtom);
    if (!plan) return null;
    return (
        <Box onClick={() => {
            setDialogState({
                isOpened: true,
                planId: plan.id,
                owner: plan.owner,
            })
        }}>
            {children}
        </Box>
    );
};

export default PlanOwnerDialogClickListener;
