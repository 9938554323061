import React from "react";
import {People} from "../../constants/types";
import {Box} from "@mui/material";
import {Text} from "./Text";

export const PeoplePicture: React.FC<{
    size: number,
    people: People
}> = ({size, people}) => {
    if (!people.imgSrc) return <Text>Owner</Text>;


    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: size,
            height: size,
            borderRadius: size,
            overflow: "hidden",
        }}>
            <img
                src={people.imgSrc}
                alt={people.name}
                aria-label={people.name}
                style={{
                    width: "100%",
                }}/>
        </Box>
    );
};
