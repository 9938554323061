import React, {FC, useEffect} from "react";
import {usePlan} from "../../hooks/usePlan";
import {useUserId} from "../../hooks/useUserId";

type IProps = {
    children: React.ReactNode,
};

/**
 * **Responsibility**: Add React Helmet to DOM
 */
// TODO: Use a proper lib to do that
const WithHelmet: FC<IProps> = ({children}) => {
    const plan = usePlan();
    useUserId();

    useEffect(() => {
        document.title = `Suro - ${plan?.name || ""}`;
    }, [plan]);

    return (<>{children}</>);
};

export default WithHelmet;
