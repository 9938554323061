import React, {FC, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {filteringAtom} from "../../constants/atoms";

type IProps = {
    children: React.ReactNode,
};

const WithUrlFilters: FC<IProps> = ({children}) => {
    const [searchParams] = useSearchParams()
    const setFiltering = useSetRecoilState(filteringAtom);

    useEffect(() => {
        setFiltering((prevState) => ({
            ...prevState,
            STATUS: searchParams.get('STATUS')?.split(",") ?? [],
            GROUP_PM: searchParams.get('GROUP_PM')?.split(",") ?? [],
            ENTITIES: searchParams.get('ENTITIES')?.split(",") ?? [],
        }));
    }, [searchParams, setFiltering]);

    return (<>{children}</>);
};

export default WithUrlFilters;
