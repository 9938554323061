import React from "react";
import {
    Box,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import {useFiltering} from "../../hooks/useFiltering";

type IProps = {};

export const FilteringMenuBpiSection: React.FC<IProps> = () => {
    const {filteringValues, onFilteringBoxClick} = useFiltering();

    return (
        <Box className={"filter-menu-client-name-bpi-section"} sx={theme => ({ display: "flex", marginTop: theme.spacing(2), marginLeft: theme.spacing(1.5) })}>
            <FormControlLabel sx={theme => ({
                borderRadius: theme.spacing(1),
                backgroundColor: filteringValues["CLIENT_NAME"].includes("Bpifrance") ? "#DDDDDD" : "#F7F7F7",
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(2),
                marginRight: 0,
                '.MuiFormControlLabel-label': {
                    fontSize: 14,
                    fontWeight: 700,
                    width: "100%",
                },
            })} control={
                <Checkbox
                    checked={filteringValues["CLIENT_NAME"].includes("Bpifrance")}
                    sx={{
                        margin: 0,
                        '& .MuiSvgIcon-root': {fontSize: 24},
                        color: "#717171",
                        '&.Mui-checked': {
                            color: "#717171",
                        },
                    }}
                    onClick={() => onFilteringBoxClick("CLIENT_NAME", "Bpifrance")}
                />
            } label={"Only display Bpifrance milestones"}/>
        </Box>
    )
};