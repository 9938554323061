import React from "react";
import {SvgIcon} from "@mui/material";

export const EditIcon: React.FC = () => {
    return (
        <SvgIcon sx={{
            heigth: "16px",
            width: "16px"
        }} fillOpacity={1} width="16" height="16" viewBox="0 0 16 16"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <g id="Pencil / 24 / Outline">
                <path id="Vector"
                      d="M14.6668 4.82666C14.6673 4.73892 14.6505 4.65194 14.6174 4.57072C14.5842 4.48949 14.5353 4.41562 14.4735 4.35332L11.6468 1.52666C11.5845 1.46487 11.5107 1.41599 11.4294 1.38281C11.3482 1.34963 11.2612 1.33282 11.1735 1.33332C11.0858 1.33282 10.9988 1.34963 10.9176 1.38281C10.8363 1.41599 10.7625 1.46487 10.7002 1.52666L8.81351 3.41332L1.52684 10.7C1.46505 10.7623 1.41617 10.8362 1.38299 10.9174C1.34982 10.9986 1.333 11.0856 1.33351 11.1733V14C1.33351 14.1768 1.40375 14.3464 1.52877 14.4714C1.65379 14.5964 1.82336 14.6667 2.00017 14.6667H4.82684C4.92013 14.6717 5.01344 14.6571 5.10072 14.6238C5.188 14.5905 5.26731 14.5393 5.33351 14.4733L12.5802 7.18666L14.4735 5.33332C14.5344 5.26871 14.5839 5.19435 14.6202 5.11332C14.6266 5.06018 14.6266 5.00646 14.6202 4.95332C14.6233 4.92229 14.6233 4.89102 14.6202 4.85999L14.6668 4.82666ZM4.55351 13.3333H2.66684V11.4467L9.28684 4.82666L11.1735 6.71332L4.55351 13.3333ZM12.1135 5.77332L10.2268 3.88666L11.1735 2.94666L13.0535 4.82666L12.1135 5.77332Z"
                      fill="#717171"/>
            </g>
        </SvgIcon>
    )
};