import React from "react";
import Box from "@mui/material/Box";
import {HelpIcon} from "../../icons/HelpIcon";

// TODO: Move this magic string elsewhere
export const InfoButton: React.FC = () => {
    return (
        <Box
            sx={(theme) =>({
                cursor: 'pointer',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            })}
            onClick={() => window
                .open(
                    "https://www.notion.so/m33/Build-a-macroplan-87c24ddb06d14736b669160656fc5b36",
                    "_blank"
                )}>
            <HelpIcon/>
        </Box>
    )
};
