import React from "react";
import Box from "@mui/material/Box";

export const TaskDateLabel: React.FC<{ label: string }> = ({label}) => {
    return (
        <Box sx={{
            fontSize: "12px",
            lineHeight: "12px",
            width: "100%",
            flexBasis: "12px",
            textAlign: "center"
        }}>
            {label}
        </Box>
    )
};