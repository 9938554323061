import React, {RefObject} from "react";
import Box from "@mui/material/Box";
import {DateType} from "../../constants/types";
import {TaskDate} from "../presentational/TaskDate";
import {usePlan} from "../../hooks/usePlan";
import {useMinMaxDates} from "../../hooks/useMinMaxDates";

type IProps = {
    type: DateType,
    date: Date | null,
    wrapperWidth: number | undefined
    parentRef: RefObject<HTMLDivElement | undefined>,
    reversed?: boolean,
    done?: boolean,
};

export const TaskTimelineDate: React.FC<IProps> = ({done, reversed, parentRef, date, wrapperWidth, type}) => {
    const plan = usePlan();
    const [minDate, maxDate] = useMinMaxDates();
    if (!plan) return null;
    if (!wrapperWidth) return null;
    if (!date) return null;
    const dateTime = date.getTime();
    const minDateTime = minDate.getTime();
    const dateDiff = dateTime - minDateTime;
    const maxDateTime = maxDate.getTime();
    const maxDateDiff = maxDateTime - minDateTime;
    const leftPositionPercentage = dateDiff / maxDateDiff;
    // TODO: Create a utils function for this
    const dateLabel = date.toLocaleDateString("fr-FR").slice(0, 5);
    return (
        <Box
            className={"task-timeline-date"}
            ref={parentRef} sx={{
            width: "35px",
            height: "100%",
            zIndex: 1,
            position: "absolute",
            left: leftPositionPercentage * wrapperWidth,
            transform: "translateX(-50%)",
        }}>
            <TaskDate done={done} type={type} label={dateLabel} reversed={reversed}/>
        </Box>
    )
};
