import {SiriusVeil} from "../constants/types";
import {useMutation} from "react-query";
import {queryClient} from "../utils/query-client";
import {makePatchRequest} from "../utils/http";
import {getEditSiriusApiUrl} from "../utils/urls";
import {formatDate} from "../utils/dates";

export const useUpdateSiriusVeil = (): (siriusVeil: SiriusVeil) => void => {
    const mutation = useMutation({
        mutationFn: async (siriusVeil: SiriusVeil) => {
            return makePatchRequest({
                url: getEditSiriusApiUrl(siriusVeil.id),
                body: {
                    title: siriusVeil.title,
                    from: formatDate(siriusVeil.from),
                    to: formatDate(siriusVeil.to),
                }
            })
        },
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["data"]})
    });
    return (siriusVeil: SiriusVeil) => {
        mutation.mutate(siriusVeil);
    };
};