import {usePlan} from "./usePlan";
import {useMutation} from "react-query";
import {makePostRequest} from "../utils/http";
import {getCreateMilestoneApiUrl} from "../utils/urls";
import {Milestone} from "../constants/types";
import {queryClient} from "../utils/query-client";
import {formatDate} from "../utils/dates";

export const useCreateMilestone = () => {
    const plan = usePlan();
    const mutation = useMutation({
        mutationFn: async (milestone: Milestone) => {
            if (!plan) return;
            await makePostRequest({
                url: getCreateMilestoneApiUrl(),
                body: {
                    planId: plan.id,
                    name: milestone.name,
                    type: milestone.type,
                    date: formatDate(milestone.date),
                }
            })
        },
        onSuccess: () => queryClient.invalidateQueries({
            queryKey: ["data"]
        }),
    });
    return (milestone: Milestone) => mutation.mutate(milestone);
};