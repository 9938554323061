import React, {FC} from "react";
import {Box} from "@mui/material";
import {EditableTextField} from "../interactive/fields/EditableTextField";
import {usePlan} from "../../hooks/usePlan";
import {useUpdatePlan} from "../../hooks/useUpdatePlan";

type IProps = {};

/**
 * **Responsibility**: Plan Title Component
 */
const PlanTitle: FC<IProps> = () => {
    const plan = usePlan();
    const setPlan = useUpdatePlan();

    if (!plan) return null;
    return (
        <Box id={'plan-title'}>
            <EditableTextField
                variant={"h1"}
                value={plan.name}
                onChange={value => {
                    setPlan({
                        ...plan,
                        name: value,
                    })
                }
                }
            />
        </Box>
    );
};
export default PlanTitle;
