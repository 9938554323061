import React, {FC} from "react";
import {Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {editTaskDialogAtom} from "../../../constants/atoms";
import {useGetTask} from "../../../hooks/useGetTask";
// import ArchiveProjectButton from "../button/ArchiveProjectButton";
import {ArrowDropDown, ArrowDropUp, DeleteForever, Link} from "@mui/icons-material";
import {useDelete} from "../../../hooks/useDelete";
import {useUpTask} from "../../../hooks/useUpTask";
import {useDownTask} from "../../../hooks/useDownTask";

const TaskMenu: FC = () => {
    const dialog = useRecoilValue(editTaskDialogAtom);
    const setDialog = useSetRecoilState(editTaskDialogAtom);
    const deleteTask = useDelete("tasks");
    const moveUp = useUpTask();
    const moveDown = useDownTask();
    const task = useGetTask(dialog.taskId || "");
    if (!task) return null;
    const id = task.id;
    return (
        <Box>
            <Menu
                open={dialog.isOpened}
                aria-controls={dialog.isOpened ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={dialog.isOpened ? 'true' : undefined}
                onClose={() => {
                    if (!dialog.taskId) return;
                    setDialog({isOpened: false,})
                }}
                anchorEl={dialog.menuAnchorElement}>
                <MenuItem id={"up-button"} onClick={() => moveUp({...task, id})}>
                    <ListItemIcon>
                        <ArrowDropUp fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Up</ListItemText>
                </MenuItem>
                <MenuItem id={"down-button"} onClick={() => moveDown({...task, id})}>
                    <ListItemIcon>
                        <ArrowDropDown fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Down</ListItemText>
                </MenuItem>
                {/*<ArchiveProjectButton taskId={task.id}/>*/}
                {task.associatedPlan && (
                    <MenuItem onClick={() => window.location.href = `${window.location.origin}/${task.associatedPlan}`}>
                        <ListItemIcon>
                            <Link fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Show Sub-Suro</ListItemText>
                    </MenuItem>
                )}
                <Divider/>
                <MenuItem id={"delete-task"} onClick={() => deleteTask(id)}>
                    <ListItemIcon>
                        <DeleteForever fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default TaskMenu;
