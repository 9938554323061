import React from "react";
import {SvgIcon} from "@mui/material";

export const HelpIcon: React.FC = () => {
    return (
        <SvgIcon
            sx={{width: "64px", height: "60px"}}
            width="64" height="60" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_12867_8964)">
                <circle cx="32" cy="28" r="19.5" fill="white" stroke="#DDDDDD"/>
                <path
                    d="M30.6169 29.616C30.6169 29.3067 30.6755 29.0187 30.7929 28.752C30.9209 28.4853 31.0595 28.2667 31.2089 28.096C31.3689 27.9147 31.6302 27.632 31.9929 27.248C32.3235 26.8853 32.5742 26.56 32.7449 26.272C32.9262 25.9733 33.0169 25.6427 33.0169 25.28C33.0169 24.832 32.8835 24.4853 32.6169 24.24C32.3502 23.9947 32.0035 23.872 31.5769 23.872C31.2675 23.872 30.9582 23.9573 30.6489 24.128C30.3395 24.2987 30.0889 24.528 29.8969 24.816L28.5529 23.568C28.9262 23.0987 29.3849 22.736 29.9289 22.48C30.4729 22.224 31.0702 22.096 31.7209 22.096C32.3075 22.096 32.8515 22.2133 33.3529 22.448C33.8649 22.6827 34.2702 23.0347 34.5689 23.504C34.8782 23.9627 35.0329 24.5227 35.0329 25.184C35.0329 25.7493 34.9049 26.2507 34.6489 26.688C34.4035 27.1253 34.0622 27.5573 33.6249 27.984L33.2729 28.32C32.9422 28.608 32.6915 28.8587 32.5209 29.072C32.3502 29.2747 32.2595 29.488 32.2489 29.712L32.2009 30.416H30.6169V29.616ZM31.4489 34.128C31.1075 34.128 30.8195 34.016 30.5849 33.792C30.3502 33.5573 30.2329 33.2747 30.2329 32.944C30.2329 32.6133 30.3502 32.3307 30.5849 32.096C30.8302 31.8613 31.1182 31.744 31.4489 31.744C31.7795 31.744 32.0622 31.8613 32.2969 32.096C32.5315 32.3307 32.6489 32.6133 32.6489 32.944C32.6489 33.2747 32.5315 33.5573 32.2969 33.792C32.0622 34.016 31.7795 34.128 31.4489 34.128Z"
                    fill="#222222"/>
            </g>
            <defs>
                <filter id="filter0_d_12867_8964" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="6"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12867_8964"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12867_8964" result="shape"/>
                </filter>
            </defs>
        </SvgIcon>
    )
};