import React, {FC} from "react";
import {Box} from "@mui/material";

type IProps = {
    children: React.ReactNode[],
};

/**
 * **Responsibility**: Display a list of node, but with margin between
 */
// TODO: Reflect on this
const WithMarginHorizontalList: FC<IProps> = ({children}) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>
            {children.map((child, index) => (
                <Box key={index} sx={theme => ({margin: theme.spacing(1)})}>
                    {child}
                </Box>
            ))}
        </Box>
    );
};

export default WithMarginHorizontalList;
