import React from "react";
import {DateType} from "../../constants/types";
import {SvgIcon} from "@mui/material";

// TODO: Split this component
export const TaskDateIcon: React.FC<{ type: DateType, done?: boolean }> = ({type, done}) => {
    switch (type) {
        case "Scheduled":
            return (
                <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="#F7F7F7">
                    <circle
                        fill={done ? "#B0B0B0" : "#F7F7F7"}
                        cx="10" cy="10" r="7.25"
                        stroke="#222222"
                        strokeWidth="1.5"
                    />
                </SvgIcon>
            )
        case "Start":
            return (
                <SvgIcon width="4" height="21" viewBox="0 0 4 21" fill="#F7F7F7">
                    <path
                        d="M2 2.25V18.25"
                        stroke="#222222"
                        strokeWidth="1.5"
                    />
                </SvgIcon>
            )
        case "ExpectedLate":
            return (
                <SvgIcon width="20" height="21" viewBox="0 0 20 21" fill="#F7F7F7" xmlns="http://www.w3.org/2000/svg">
                    <path fill={done ? "#B0B0B0" : "#F7F7F7"}
                          d="M3.21353 17.5L10 3.92705L16.7865 17.5H3.21353Z"
                          stroke="#222222"
                          strokeWidth="1.5"
                    />
                </SvgIcon>
            )
    }
    return null
};
