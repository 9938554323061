import {useRecoilValue, useSetRecoilState} from "recoil";
import {filteringAtom, FilteringType, FilteringValueTypes} from "../constants/atoms";
import {useSearchParams} from "react-router-dom";

export const useFiltering = () => {
    const filteringValues = useRecoilValue(filteringAtom);
    const setFiltering = useSetRecoilState(filteringAtom);
    const [searchParams, setSearchParams] = useSearchParams();

    const updateSearchParams = (type: FilteringType, values: FilteringValueTypes[]) => {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        if (values.length > 0) {
            newSearchParams.set(type, values.join(','));
        } else {
            newSearchParams.delete(type);
        }
        setSearchParams(newSearchParams);
    };

    const onFilteringBoxClick = (type: FilteringType, value: FilteringValueTypes) => {
        const newValues = filteringValues[type].includes(value)
            ? filteringValues[type].filter(item => item !== value)
            : [...filteringValues[type], value];

        setFiltering({
            ...filteringValues,
            [type]: newValues,
        });

        updateSearchParams(type, newValues);
    };

    const resetFilterType = (type: FilteringType) => {
        setFiltering({
            ...filteringValues,
            [type]: [],
        });
        updateSearchParams(type, []);
    };

    return {
        filteringValues,
        onFilteringBoxClick,
        resetFilterType,
    };
};