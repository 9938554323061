import {FC} from "react";
import {Autocomplete, Box, Dialog, Divider, TextField, Typography} from "@mui/material";
import {editOwnerDialogAtom} from "../../../constants/atoms";
import {useRecoilState} from "recoil";
import {usePlan} from "../../../hooks/usePlan";
import {usePeoples} from "../../../hooks/usePeoples";
import {useUpdatePlan} from "../../../hooks/useUpdatePlan";
import {filterOwnerOptions} from "../../../utils/inputsFiltering";

export const PlanOwnerDialog: FC = () => {
    const [dialog, setDialogState] = useRecoilState(editOwnerDialogAtom);
    const plan = usePlan();
    const peoples = usePeoples();
    const setPlan = useUpdatePlan();
    const onClose = () => {
        if (dialog.taskId) {
            setDialogState({isOpened: false});
        }
        if (dialog.planId) {
            if (!plan) return;
            setPlan({
                ...plan,
                owner: dialog.owner || null,
            });
            setDialogState({isOpened: false});
        }
    };
    return (
        <Box>
            <Dialog
                open={dialog.isOpened}
                onClose={onClose}
            >
                <Typography sx={theme => ({
                    fontWeight:700,
                    fontSize: "16px",
                    marginBottom: theme.spacing(3),
                })}>
                    Select the plan owner
                </Typography>
                <Divider sx={theme => ({
                    marginBottom: theme.spacing(4),
                    marginX:-3,
                })} />
                <Autocomplete
                    id={"plan-owner-autocomplete-field"}
                    sx={theme => ({
                        width: "100%",
                        marginTop: theme.spacing(3)
                    })}
                    value={!!dialog.owner ? dialog.owner : null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => `${option.email} (${option.name})`}
                    filterOptions={filterOwnerOptions}
                    onChange={(e, value) => setDialogState({
                        ...dialog,
                        owner: value ? {
                            ...value,
                            id: value.id,
                            name: value.name,
                            imgSrc: value.imgSrc,
                            email: value.email,
                        } : null,
                    })}
                    options={peoples}
                    renderInput={(params) => <TextField
                        {...params}
                        onKeyDown={e => {
                            if (e.shiftKey) return;
                            if (e.code !== "Enter") return;
                            onClose();
                        }}
                        autoFocus
                    />}
                />
            </Dialog>
        </Box>
    )
};
