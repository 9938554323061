import React, {FC} from "react";
import {Avatar, Box} from "@mui/material";
import {People} from "../../constants/types";

type IProps = {
    people: People,
};

/**
 * **Responsibility**: TBC
 */
const PeoplePictureComponent: FC<IProps> = ({people}) => {
    if (!people.imgSrc) return null;
    return (
        <Box>
            <Avatar alt={people.name} src={people.imgSrc} sx={{
                width: "2.5rem",
                height: "2.5rem",
            }}/>
        </Box>
    );
};

export default PeoplePictureComponent;
