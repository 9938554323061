import React, {FC} from "react";
import {Box, Divider} from "@mui/material";

type IProps = {};

/**
 * **Responsibility**: TBC
 */
const TaskDividerComponent: FC<IProps> = () => {
    return (
        <Box sx={theme => ({
            marginLeft: theme.spacing(3)
        })}>
            <Divider/>
        </Box>
    );
};

export default TaskDividerComponent;
