import {useMutation} from "react-query";
import {queryClient} from "../utils/query-client";
import {makeDeleteRequest} from "../utils/http";
import {getDeletePageApiUrl} from "../utils/urls";

type ObjectType = "plans" | "projects" | "tasks" | "date-items" | "milestones" | "sirius-veils";

// TODO: Find a better way to do this
export const useDelete = (objectType: ObjectType): (id: string) => void => {
    const mutation = useMutation({
        mutationFn: async (id: string) => {
            return makeDeleteRequest({
                url: getDeletePageApiUrl(id, objectType),
                body: {},
            })
                .catch(console.error)
        },
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["data"]})
    });

    return (id: string) => {
        mutation.mutate(id);
    };
};
