import React, {FC} from "react";
import {Box, SvgIcon} from "@mui/material";

type IProps = {
    className?: string;
};

/**
 * **Responsibility**: TBC
 */
const DoneIcon: FC<IProps> = ({className}) => {
    return (
        <Box className={className} sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "1.8rem",
            height: "1.8rem",
        }}>
            <SvgIcon width={"1.8rem"} height={"1.8rem"} viewBox="0 0 16 16">
                <rect width="16" height="16" rx="8" fill="#222222"/>
                <path
                    d="M12.3902 5.10984C12.5366 5.25629 12.5366 5.49373 12.3902 5.64017L7.14016 10.8899C6.99372 11.0364 6.75629 11.0364 6.60985 10.8899L3.98485 8.26518C3.83839 8.11874 3.83838 7.8813 3.98482 7.73485C4.13126 7.58839 4.3687 7.58838 4.51515 7.73482L6.87499 10.0945L11.8598 5.10983C12.0063 4.96339 12.2437 4.96339 12.3902 5.10984Z"
                    fill="white"/>
            </SvgIcon>
        </Box>
    );
};

export default DoneIcon;
