import React from "react";
import {SvgIcon} from "@mui/material";

export const DelayedIcon: React.FC = () => {
    return (
        <SvgIcon fillOpacity={0} width="20" height="20" viewBox="0 0 20 20" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <path d="M3.21353 17.25L10 3.67705L16.7865 17.25H3.21353Z" stroke="#222222"
                  strokeWidth="1.5"/>
        </SvgIcon>
    )
};