import React from "react";
import {SBox} from "./SBox";
import {AddMenu} from "../interactive/menu/AddMenu";
import {SortMenu} from "../interactive/menu/SortMenu";
import {FilterMenu} from "../interactive/menu/FilterMenu";

export const PlanActions: React.FC = () => {
    return (
        <SBox flex>
            <FilterMenu/>
            <SortMenu/>
            <AddMenu/>
        </SBox>
    )
};