import React from "react";
import {DateType} from "../../constants/types";
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import {SxProps} from "@mui/system";

// TODO: Find a more elegant way to do this
export const DateIcon: React.FC<{
    type?: DateType,
    whiteBackground?: boolean
}> = ({
          type,
          whiteBackground
      }) => {
    const whiteBackgroundColor: SxProps = {
        backgroundColor: whiteBackground ? "white" : "#F7F7F7",
    }
    const commonStyle: SxProps = {
        width: 20,
        height: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
    if (!type) return null;
    const iconSize = 20;
    switch (type) {
        case "Start":
            return <HorizontalRuleOutlinedIcon sx={{
                ...commonStyle,
                transform: "rotate(90deg)",
            }}/>
        case "Scheduled":
            return <CircleOutlinedIcon width={iconSize} height={iconSize} sx={{
                ...whiteBackgroundColor,
                ...commonStyle,
            }}/>
        case "ExpectedLate":
            return <ChangeHistoryOutlinedIcon width={iconSize} height={iconSize} sx={{
                ...whiteBackgroundColor,
                ...commonStyle,
            }}/>
        case "DoneDelayed":
            return <ChangeHistoryTwoToneIcon width={iconSize} height={iconSize} sx={{
                ...whiteBackgroundColor,
                ...commonStyle,
            }}/>
        case "DoneOnTime":
            return <CircleTwoToneIcon width={iconSize} height={iconSize} sx={{
                ...whiteBackgroundColor,
                ...commonStyle,
            }}/>
        default:
            return null;
    }
};
