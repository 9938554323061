import React from "react";
import {People} from "../../../constants/types";
import {Autocomplete, TextField} from "@mui/material";
import {usePeoples} from "../../../hooks/usePeoples";
import {filterOwnerOptions} from "../../../utils/inputsFiltering";

type IProps = {
    value: People | null,
    onChange: (owner: People | null) => void,
};

export const OwnerField: React.FC<IProps> = ({value, onChange}) => {
    const peoples = usePeoples();
    return (
        <Autocomplete
            id={"task-owner-autocomplete-field"}
            sx={() => ({
                width: "100%",
                "& > div > div > fieldset": {
                    border: "none",
                },
                "& > div > div > input": {
                    padding: "0px!important",
                },
                "& > div > div": {
                    padding: "0px!important",
                }
            })}
            value={!!value ? value : null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => `${option.email} (${option.name})`}
            filterOptions={filterOwnerOptions}
            onChange={(event, value) => onChange(value)}
            options={peoples}
            renderInput={(params) => <TextField
                {...params}
            />}
        />
    )
};