import React, {FC} from "react";
import {Box} from "@mui/material";
import PlanOwnerDialogClickListener from "../interactive/click/PlanOwnerDialogClickListener";
import PlanOwnerPicComponent from "./PlanOwnerPicComponent";
import {PlanActions} from "./PlanActions";

type IProps = {};

/**
 * **Responsibility**: Wrapper for Plan Owner Component
 */
const OwnerHeaderColumn: FC<IProps> = () => {
    return (
        <Box>
            <Box sx={{
                display: "flex",
                alignItems: "center",
            }}>
                <Box sx={theme => ({
                    marginRight: theme.spacing(2),
                    marginLeft: theme.spacing(1),
                })}>
                    <PlanActions/>
                </Box>
                <PlanOwnerDialogClickListener>
                    <PlanOwnerPicComponent/>
                </PlanOwnerDialogClickListener>
            </Box>
        </Box>
    );
};

export default OwnerHeaderColumn;
