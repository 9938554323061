import React, {FC} from "react";
import {Box, Divider, Tooltip} from "@mui/material";
import {useSetRecoilState} from "recoil";
import {editMilestoneDialogAtom} from "../../constants/atoms";
import {usePlan} from "../../hooks/usePlan";
import {useMilestone} from "../../hooks/useMilestone";
import {useMinMaxDates} from "../../hooks/useMinMaxDates";

type IProps = {
    id: string;
};

/**
 * **Responsibility**: TBC
 */
const Milestone: FC<IProps> = ({id}) => {
    const milestone = useMilestone(id);
    const plan = usePlan();
    const setEditMilestoneDialogState = useSetRecoilState(editMilestoneDialogAtom);
    const [minDate, maxDate] = useMinMaxDates();

    if (!milestone) return null;
    if (!plan) return null;

    const isIntermediate = milestone.type === "Intermediate";
    const isEndOfStaffing = milestone.type === "EndOfStaffing";
    const {date} = milestone;

    // TODO: Find a more elegant way to position the milestone
    const allTimelineBoxes = document.getElementsByClassName("timeline-box");
    const headerBox = document.getElementById("header-bar");
    const contentBox = document.getElementById("plan-content");
    const contentHeight = contentBox?.clientHeight;
    if (!contentHeight) return null;
    const firstTimelineBox = allTimelineBoxes[0] as HTMLDivElement;
    const lastTimelineBox = allTimelineBoxes[allTimelineBoxes.length - 1] as HTMLDivElement;
    const dateItemTime = date.getTime();
    const planMinDateTime = minDate.getTime();
    const planMaxDateTime = maxDate.getTime();

    // TODO: Move this check somewhere else
    if (dateItemTime < planMinDateTime) throw new Error("There's a problem with dates, planMinDate is after this date");

    const marginLeftPercentage = (dateItemTime - planMinDateTime) / (planMaxDateTime - planMinDateTime);

    if (!firstTimelineBox) return null;
    if (!lastTimelineBox) return null;
    if (!headerBox) return null;

    const onMilestoneClick = () => {
        setEditMilestoneDialogState({
            isOpened: true,
            id,
            date: milestone.date,
            milestoneType: milestone.type,
            name: milestone.name,
        })
    };

    // TODO: Move this in a centralized dedicated files
    const color = isIntermediate ? "#D9D9D9" : (isEndOfStaffing ? "#222222" : "#EC5757");

    return (
        <Box
            sx={{
                position: "absolute",
                top: headerBox.offsetHeight,
                left: firstTimelineBox.offsetLeft + firstTimelineBox.clientWidth * marginLeftPercentage,
                height: contentHeight,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                transform: `translate(-50%, -12px)`,
            }}
        >
            <Tooltip
                onClick={onMilestoneClick}
                title={`${date.toLocaleDateString("fr-FR").slice(0, 5)} - ${milestone.name}`}
                placement={"top"}
                arrow
            >
                <Box className={"milestone-circle"} sx={{
                    minWidth: "12px",
                    minHeight: "12px",
                    borderRadius: "12px",
                    backgroundColor: color,
                    transform: "translate(0px, 50%)",
                }}></Box>
            </Tooltip>
            <Divider orientation="vertical" sx={{
                borderRightWidth: isEndOfStaffing ? "unset" : "medium",
                borderRightStyle: isIntermediate ? "dashed" : null,
                borderColor: color,
            }}/>
        </Box>
    );
};

export default Milestone;
