import React from "react";
import {Project} from "../../../constants/types";
import {Autocomplete, TextField} from "@mui/material";
import {useProjects} from "../../../hooks/useProjects";
import {filterProjectOptions} from "../../../utils/inputsFiltering";

type IProps = {
    values: Project[],
    onChange: (projects: Project[]) => void,
};

// TODO: Learn the proper way to do this
export const ProjectsField: React.FC<IProps> = ({values, onChange}) => {
    const projects = useProjects();
    return (
        <Autocomplete
            id={"task-project-autocomplete-field"}
            sx={() => ({
                width: "100%",
                "& > div > div > fieldset": {
                    border: "none",
                },
                "& > div > div > input": {
                    padding: "0px!important",
                },
                "& > div > div": {
                    padding: "0px!important",
                }
            })}
            multiple
            loading={projects.length === 0}
            loadingText={"Loading..."}
            filterOptions={filterProjectOptions}
            value={!!values ? values : []}
            onChange={(event, values) => onChange(values)}
            options={projects}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => `[${option.id}] ${option.clientName ? `[${option.clientName}]` : ""} ${option.name}`}
            renderInput={(params) => <TextField
                {...params}
            />}
        />
    )
};
