import React from "react";
import {MilestoneType} from "../../../constants/types";
import {MenuItem, Select} from "@mui/material";
import {Text} from "../../presentational/Text";

type IProps = {
    value: MilestoneType | undefined,
    onChange: (type: MilestoneType) => void,
};

const options: Array<{ value: MilestoneType, label: string }> = [{
    value: "Default",
    label: "Milestone",
}, {
    value: "Intermediate",
    label: "Intermediate",
}, {
    value: "EndOfStaffing",
    label: "End of staffing",
}];

export const MilestoneTypeField: React.FC<IProps> = ({value, onChange}) => {
    return (
        <Select
            value={value}
            onChange={event => onChange(event.target.value as MilestoneType)}
            sx={{
                "& > div": {
                    display: "flex",
                    alignItems: "center",
                },
                "& div": {
                    padding: 0,
                },
                "& fieldset": {
                    border: "none",
                }
            }}
        >
            {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    <Text>
                        {option.label}
                    </Text>
                </MenuItem>
            ))}
        </Select>
    )
};