import React from "react";
import {Typography} from "@mui/material";
import {SxProps} from "@mui/system";

type IProps = {
    bold?: boolean,
    noDecoration?: boolean,
    children: React.ReactNode,
    optional?: boolean,
    buttonSecondaryMedium?: boolean,
    buttonPrimaryRegular?: boolean,
    pOneRegular?: boolean,
    pTwoMedium?: boolean,
    secondaryFiveHundred?: boolean,
    secondaryFourHundred?: boolean,
    grey?: boolean,
    className?: string,
};

// TODO: I regret this. Sorry to my future self
export const Text: React.FC<IProps> = (
    {
        buttonPrimaryRegular,
        pOneRegular,
        secondaryFiveHundred,
        buttonSecondaryMedium,
        optional,
        bold,
        children,
        noDecoration,
        secondaryFourHundred,
        pTwoMedium,
        grey,
        className
    }
) => {
    const greyStyle: SxProps = grey ? {
        color: "#717171",
    } : {};
    const pTwoMediumStyle: SxProps = pTwoMedium ? {
        fontSize: "12px",
    } : {};
    const secondaryFourHundredStyle: SxProps = secondaryFourHundred ? {
        color: "#717171",
    } : {};
    const secondaryFiveHundredStyle: SxProps = secondaryFiveHundred ? {
        color: "#222222",
    } : {};
    const pOneRegularStyle: SxProps = pOneRegular ? {
        fontSize: "14px",
    } : {};
    const buttonPrimaryRegularStyle: SxProps = buttonPrimaryRegular ? {
        fontSize: "16px",
    } : {};
    const buttonSecondaryMediumStyle: SxProps = buttonSecondaryMedium ? {
        fontSize: "14px",
    } : {};
    const optionalStyle: SxProps = optional ? {
        color: "#717171",
    } : {};
    const boldStyle: SxProps = bold ? {
        fontWeight: "bold",
    } : {};
    const decorationStyle: SxProps = noDecoration ? {
        cursor: "default",
    } : {};
    return (
        <Typography sx={Object.assign({},
            boldStyle,
            decorationStyle,
            optionalStyle,
            buttonPrimaryRegularStyle,
            buttonSecondaryMediumStyle,
            pOneRegularStyle,
            secondaryFiveHundredStyle,
            secondaryFourHundredStyle,
            pTwoMediumStyle,
            greyStyle,
        )} className={className}>
            {children}
        </Typography>
    )
};
