import {DataFromAPI} from "./useGetDataFromAPI";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {makeGetRequest} from "../utils/http";
import {
    getEverythingApiUrl,
    getGroupPMApiUrl,
    getPeopleApiUrl,
    getProjectApiUrl
} from "../utils/urls";
import {processData, ProcessedData} from "./processData";
import {useSetRecoilState} from "recoil";
import {loadingAtom} from "../constants/atoms";
import {useEffect} from "react";
import {People, Project} from "../constants/types";

// TODO: Refactor this
const getData = (id: string | undefined) => async () => {
    if (!id) throw Error("No ID");
    const data = await makeGetRequest<DataFromAPI>(getEverythingApiUrl(id));
    if (!data) throw Error("No Data");

    const processedData = processData(data);
    if (!processedData) throw Error("Process Data Error");
    return processedData;
};

const getPeopleData = async () => {
    const peoples = await makeGetRequest<People[]>(getPeopleApiUrl());
    if (!peoples) throw Error("No Users");
    return peoples || [];
};

const getProjectData = async () => {
    const projects = await makeGetRequest<Project[]>(getProjectApiUrl());
    if (!projects) throw Error("No Projects");
    return projects || [];
};

const getGroupPMData = async () => {
    const groupPMS = await makeGetRequest<String[]>(getGroupPMApiUrl());
    if (!groupPMS) throw Error("No Group PMS");
    return groupPMS || [];
};

export const useData = (): ProcessedData | undefined => {
    const {id} = useParams();
    const setLoading = useSetRecoilState(loadingAtom);
    const query = useQuery(
        {
            queryKey: "data",
            refetchInterval: 10000,
            queryFn: getData(id),
        },
    );
    const peopleQuery = useQuery(
        {
            queryKey: "people",
            queryFn: getPeopleData,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    );
    const projectQuery = useQuery(
        {
            queryKey: "project",
            queryFn: getProjectData,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    );
    const groupPMQuery = useQuery(
        {
            queryKey: "groupPM",
            queryFn: getGroupPMData,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    );
    useEffect(() => {
        if (query.isError) return;
        if (query.isLoading) return;
        if (peopleQuery.isError) return;
        if (peopleQuery.isLoading) return;
        setLoading(false);
    }, [setLoading, query, peopleQuery]);

    return {
        tasks: query.data?.tasks || [],
        milestones: query.data?.milestones || [],
        siriusVeils: query.data?.siriusVeils || [],
        plan: query.data?.plan || {
            tasks: [],
            siriusVeils: [],
            milestones: [],
            id: "",
            mainSuro: false,
            minDate: new Date(),
            maxDate: new Date(),
            owner: null,
            name: "",
        },
        peoples: peopleQuery.data || [],
        projects: projectQuery.data || [],
        groupPMS: groupPMQuery.data || [],
    };
};
