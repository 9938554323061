import {Task} from "../constants/types";
import {useRecoilValue} from "recoil";
import {filteringAtom} from "../constants/atoms";
import {useTasks} from "./useTasks";

export const useFilteredTasks = (): Task[] => {
    const tasks = useTasks();
    const filteringValues = useRecoilValue(filteringAtom);

    return tasks
        .filter(task => filteringValues.STATUS.length === 0 ||
            (task.status && filteringValues.STATUS.includes(task.status)))
        .filter(task =>
            filteringValues.GROUP_PM.length === 0 ||
            task.projects.some(project =>
                project.groupPm !== null &&
                filteringValues.GROUP_PM.includes(project.groupPm)
            )
        )
        .filter(task =>
            filteringValues.ENTITIES.length === 0 ||
            task.projects.some(project =>
                project.entities.some(entity => filteringValues.ENTITIES.includes(entity.toString()))
            )
        )
        .filter(task =>
            filteringValues.CLIENT_NAME.length === 0 ||
            task.projects.some(project =>
                project.clientName !== null &&
                filteringValues.CLIENT_NAME.includes(project.clientName)
            )
        );
};