import {createTheme} from "@mui/material";


export const newTheme = createTheme({
    typography: {
        fontFamily: "Avertator",
        h1: {
            fontSize: "1.8rem",
        },
        h2: {
            fontSize: "1.8rem",
        },
        h3: {
            fontSize: "0.81rem",
        },
        h4: {
            fontSize: "0.81rem",
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: createTheme().spacing(3),
                    minWidth: "50vw",
                    textAlign: "center",
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    whiteSpace: "pre-line",
                    borderRadius: "4px",
                    margin: "4px 8px",
                    backgroundColor: "white",
                    color: "black",
                    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
                    maxWidth: "160px",
                },
                arrow: {
                    color: "white",
                    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
                }
            }
        }
    }
});
