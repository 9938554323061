import React, {FC} from "react";
import {Box} from "@mui/material";
import {useRecoilValue} from "recoil";
import {sortingAtom, StatusType} from "../../constants/atoms";
import TodayBar from "./TodayBar";
import Milestone from "./Milestone";
import Task from "./Task";
import {sortBy} from "lodash";
import TaskDividerComponent from "../presentational/TaskDividerComponent";
import DraggableTask from "../interactive/dragndrop/DraggableTask";
import {SiriusVeil} from "../presentational/SiriusVeil";
import {usePlan} from "../../hooks/usePlan";
import {formatDate} from "../../utils/dates";
import {useFilteredTasks} from "../../hooks/useFilteredTasks";

type IProps = {};

/**
 * **Responsibility**: Display a list of projects
 */
const Plan: FC<IProps> = () => {
    const plan = usePlan();
    const filteredTasks = useFilteredTasks();
    const sortingValues = useRecoilValue(sortingAtom);

    if (!plan) return null;
    if (!filteredTasks) return null;

    type TaskForSorting = {
        id: string;
        andon: number;
        lastScheduledDate: string;
        index: number;
    }
    // TODO: Rename this
    const andonSortMapper: Record<StatusType, number> = {
        "✅": 3,
        "🟢": 2,
        "🟠": 1,
        "🔴": 0,
    };
    // TODO: Use a dedicated lib for sorting
    const tasksForSorting = filteredTasks
        .filter(task => !!task)
        .filter(task => !!task.id)
        .map(task => ({
            id: task.id,
            andon: task.status ? andonSortMapper[task.status] : 4,
            lastScheduledDate: formatDate(task.delayedDate) || formatDate(task.scheduledDate) || null,
            index: task.index,
        }) as TaskForSorting);
    const sortingSettings = [
        sortingValues.ANDON ? "andon" : null,
        sortingValues.PRIORITY ? "lastScheduledDate" : null,
        "index"
    ].filter(sortKey => sortKey !== null);
    const sortedTasks = sortBy(
        tasksForSorting,
        sortingSettings
    ) as TaskForSorting[];

    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
            {sortedTasks.map(task => (
                <Box key={task.id} sx={{flexGrow: 1}}>
                    <DraggableTask id={task.id}>
                        <Task id={task.id}/>
                        <TaskDividerComponent/>
                    </DraggableTask>
                </Box>
            ))}
            {plan.siriusVeils.map(siriusVeilId => (
                <Box key={siriusVeilId}>
                    <SiriusVeil id={siriusVeilId}/>
                </Box>
            ))}
            {plan.milestones.map(milestoneId => (
                <Box key={milestoneId}>
                    <Milestone id={milestoneId}/>
                </Box>
            ))}
            <TodayBar/>
        </Box>
    );
};

export default Plan;
