import {Task} from "../constants/types";
import {useMutation} from "react-query";
import {makePatchRequest} from "../utils/http";
import {getEditTaskApiUrl} from "../utils/urls";
import {queryClient} from "../utils/query-client";
import {formatDate} from "../utils/dates";

export const useUpdateTask = (): (task: Task) => void => {
    const mutation = useMutation(
        {
            mutationFn: async (task: Task) => {
                return makePatchRequest({
                    url: getEditTaskApiUrl(task.id),
                    body: {
                        name: task.name,
                        risks: task.risks,
                        status: task.status,
                        statusComment: task.statusComment,
                        owner: task.owner?.email || null,
                        link: task.link,
                        startDate: formatDate(task.startDate),
                        scheduledDate: formatDate(task.scheduledDate),
                        delayedDate: formatDate(task.delayedDate),
                        projects: task.projects || [],
                        customerMilestone: task.customerMilestone,
                    },
                })
                    .catch(console.error)
            },
            onSuccess: () => queryClient.invalidateQueries({queryKey: ["data"]})
        }
    )
    return mutation.mutate;
};