import React from "react";
import {StatusType} from "../../../constants/atoms";
import {MenuItem, Select} from "@mui/material";
import DobbyComponent from "../../presentational/DobbyComponent";
import {Text} from "../../presentational/Text";
import {SBox} from "../../presentational/SBox";


type IProps = {
    value: StatusType | undefined,
    onChange: (status: StatusType) => void,
};

const options: Array<{ value: StatusType, label: string }> = [{
    value: "✅",
    label: "Done"
}, {
    value: "🟢",
    label: "On Track"
}, {
    value: "🟠",
    label: "Risk identified, the team has a plan"
}, {
    value: "🔴",
    label: "Blocker identified, the team needs help"
},];

export const StatusField: React.FC<IProps> = ({value, onChange}) => {
    return (
        <Select
            className={"status-select-field"}
            value={value}
            onChange={event => onChange(event.target.value as StatusType)}
            sx={{
                "& > div": {
                    display: "flex",
                    alignItems: "center",
                },
                "& div": {
                    padding: 0,
                },
                "& fieldset": {
                    border: "none",
                }
            }}
        >
            {options.map(option => (
                <MenuItem className={"status-menu-item"} key={option.value} value={option.value}>
                    <SBox margin={[0, 1, 0, 0]}>
                        <DobbyComponent status={option.value} highlighted={false}/>
                    </SBox>
                    <Text>
                        {option.label}
                    </Text>
                </MenuItem>
            ))}
        </Select>
    )
};