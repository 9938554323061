import React, {FC} from "react";
import {Box} from "@mui/material";

type IProps = {};

/**
 * **Responsibility**: Grindelwald component
 */
const GrindelwaldComponent: FC<IProps> = () => {
    return (
        <Box sx={{
            width: "10rem",
            height: "0.4rem",
            backgroundColor: "#1072BD",
        }}/>
    );
};

export default GrindelwaldComponent;
