import React from "react";
import {Dialog as MuiDialog, Divider} from "@mui/material";
import {SBox} from "../../presentational/SBox";
import {CrossIcon} from "../../icons/CrossIcon";
import Box from "@mui/material/Box";
import {Text} from "../../presentational/Text";
import {SecondaryButton} from "../button/SecondaryButton";
import {PrimaryButton} from "../button/PrimaryButton";

type IProps = {
    open: boolean,
    children: React.ReactNode[] | React.ReactNode,
    close: () => void,
    onSave: () => void,
    title: string,
    onDelete?: () => void,
    id?: string,
};

// TODO: Find a better split for dialog components
export const Dialog: React.FC<IProps> = ({id, open, children, close, onSave, title, onDelete}) => {
    return (
        <MuiDialog id={id} open={open}
            onClose={close}
                   sx={theme => ({
                       "& .MuiPaper-root": {
                           borderRadius: theme.spacing(1),
                           margin: 0,
                           padding: 0
                       }
                   })}>
            <SBox padding={[2.5, 3, 2.5, 3]}>
                <SBox
                    sx={(theme) =>({
                        cursor: 'pointer',
                    })}
                    absolute
                    onClick={close}
                >
                    <CrossIcon/>
                </SBox>
                <Box>
                    <Text bold className={"dialog-title"}>{title}</Text>
                </Box>
            </SBox>
            <Box>
                <Divider/>
            </Box>
            {children}
            <Divider/>
            <SBox flex spaceBetween aligned padding={[2, 3, 2, 3]}>
                <Box>
                    {onDelete && (
                        <SecondaryButton className={"delete-button"} text="Delete" onClick={onDelete} red/>
                    )}
                    <SecondaryButton text="Annuler" onClick={close} className={"dialog-cancel-button"}/>
                </Box>
                <PrimaryButton onClick={onSave} text="Enregistrer" className={"dialog-save-button"}/>
            </SBox>
        </MuiDialog>
    )
};
