import React from "react";
import {SBox} from "./SBox";
import {Text} from "./Text";
import {DateField} from "../interactive/fields/DateField";

type IProps = {
    text: string,
    icon: React.ReactNode,
    date: Date | null,
    onChange: (date: Date | null) => void,
}

export const DateFieldLine: React.FC<IProps> = ({text, icon, date, onChange}) => {
    return (
        <SBox flex className={"date-line"}>
            <SBox flex basis="40%" aligned>
                {icon}
                <SBox margin={[0, 0, 0, 1]}>
                    <Text>{text}</Text>
                </SBox>
            </SBox>
            <SBox grow margin={[0, 0, 0, 3]} bordered>
                <DateField value={date} onChange={onChange}/>
            </SBox>
        </SBox>
    )
};