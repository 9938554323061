import React from "react";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useFirstAndLastDateInPlan} from "../../hooks/useFirstAndLastDateInPlan";
import {useMinMaxDates} from "../../hooks/useMinMaxDates";

type DateLabel = {
    label: string,
    date: Date,
    left: number,
};

// TODO: Improve this
export const Timeline: React.FC = () => {
    const [minDate, maxDate] = useMinMaxDates();
    const [firstDate, lastDate] = useFirstAndLastDateInPlan();

    if (!minDate) return <></>;
    if (!maxDate) return <></>;
    if (!firstDate) return <></>;
    if (!lastDate) return <></>;

    let labels: DateLabel[] = [];
    const thereIsMoreThanOneMonth = lastDate.getMonth() !== firstDate.getMonth();
    const numberOfDaysBetweenDates = (maxDate.getTime() - minDate.getTime()) / (1000 * 60 * 60 * 24);
    if (firstDate) {
        const firstDateToAdd = new Date(firstDate);
        const percentageOfDate = (firstDateToAdd.getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime())
        labels.push({
            date: firstDateToAdd,
            label: firstDateToAdd.toLocaleString('en-US', {month: 'long'}),
            left: 100 * percentageOfDate,
        });
    }
    if (thereIsMoreThanOneMonth) {
        for (let i = 0; i < numberOfDaysBetweenDates; i++) {
            const currentDate = new Date(minDate.getTime() + (i * 1000 * 60 * 60 * 24))
            currentDate.setHours(0, 0, 0, 0);
            const percentageOfDate = (currentDate.getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime())
            if (currentDate.getMonth() === firstDate.getMonth()) continue;
            if (currentDate.getDate() === 1) labels.push({
                date: currentDate,
                left: 100 * percentageOfDate,
                label: currentDate.toLocaleString('en-US', {month: 'long'})
            })
        }
    }

    return (
        <Box sx={theme => ({
            backgroundColor: "#F7F7F7",
            padding: theme.spacing(1),
            flexGrow: 1,
            position: "relative",
            height: "calc(16px + 2*8px)",
            overflow: "hidden",
            display: "flex",
            alignItems: "flex-end",
        })}>
            {labels
                .filter(label => label.left < 87)
                .filter((label, index) => {
                    if (index === labels.length - 1) return true
                    return labels[index + 1].left - label.left > 10
                })
                .map(label => (
                    <Typography
                        align={"left"}
                        variant={"h3"}
                        sx={{
                            fontWeight: "bold",
                            color: "#717171",
                            position: "absolute",
                            left: `${label.left}%`,
                        }}>
                        {label.label}
                    </Typography>
                ))}
        </Box>
    )
};
