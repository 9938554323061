import React from "react";
import {Dialog} from "./Dialog";
import {useRecoilState} from "recoil";
import {newSiriusVeilDialogAtom} from "../../../constants/atoms";
import {SBox} from "../../presentational/SBox";
import {Text} from "../../presentational/Text";
import {TextField} from "../fields/TextField";
import {DateField} from "../fields/DateField";
import {usePlan} from "../../../hooks/usePlan";
import {useCreateSiriusVeil} from "../../../hooks/useCreateSiriusVeil";
import {useDeleteSiriusVeil} from "../../../hooks/useDeleteSiriusVeil";
import {useUpdateSiriusVeil} from "../../../hooks/useUpdateSiriusVeil";

export const SiriusVeilDialog: React.FC = () => {
    const plan = usePlan();
    const [dialog, setDialog] = useRecoilState(newSiriusVeilDialogAtom);
    const createSiriusVeil = useCreateSiriusVeil();
    const deleteSiriusVeil = useDeleteSiriusVeil();
    const updateSiriusVeil = useUpdateSiriusVeil();
    if (!plan) return null;

    const onDelete = () => {
        if (!dialog.id) return;
        onClose();
        deleteSiriusVeil(dialog.id);
    };
    const onSave = () => {
        if (!dialog.from) return;
        if (!dialog.to) return;

        if (dialog.id) {
            updateSiriusVeil({
                id: dialog.id,
                from: dialog.from,
                to: dialog.to,
                title: dialog.name || "",
            });
            onClose();
        } else {
            onClose();
            createSiriusVeil({
                id: "",
                title: dialog.name || "",
                from: dialog.from,
                to: dialog.to,
            })
        }
    };
    const onClose = () => {
        setDialog({isOpened: false})
    };
    const onTitleChange = (title: string) => setDialog({
        ...dialog,
        name: title,
    });
    const onFromChange = (date: Date | null) => setDialog({...dialog, from: date});
    const onToChange = (date: Date | null) => setDialog({...dialog, to: date});
    return (
        <Dialog
            onSave={onSave}
            onDelete={dialog.id ? onDelete : undefined}
            close={onClose}
            open={dialog.isOpened}
            title={"Sirius' Veil"}
        >
            <SBox padding={[3, 3, 3, 3]}>
                <SBox flex>
                    <Text>Period's title</Text>
                </SBox>
                <SBox bordered flex fullWidth column>
                    <TextField id={"sirius-veils-dialog-name-field"} value={dialog.name || ""}
                               onChange={onTitleChange}/>
                </SBox>
                <SBox flex>
                    <Text grey>
                        For example : testing, holidays, frozen zone...
                    </Text>
                </SBox>
                <SBox flex>
                    <SBox grow flex column padding={[0, 1, 0, 0]}>
                        <SBox flex margin={[2, 0, 0, 0]}>
                            <Text>
                                From
                            </Text>
                        </SBox>
                        <SBox bordered flex fullWidth column>
                            <DateField value={dialog.from || null} onChange={onFromChange}/>
                        </SBox>
                    </SBox>
                    <SBox grow flex column padding={[0, 0, 0, 1]}>
                        <SBox flex margin={[2, 0, 0, 0]}>
                            <Text>
                                To
                            </Text>
                        </SBox>
                        <SBox bordered flex fullWidth column>
                            <DateField value={dialog.to || null} onChange={onToChange}/>
                        </SBox>
                    </SBox>
                </SBox>
            </SBox>
        </Dialog>
    )
};