import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid, Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import {FilterExpansionType, FilteringType, FilteringValueTypes} from "../../constants/atoms";
import {useFiltering} from "../../hooks/useFiltering";

type IProps = {
    expansion: boolean,
    sectionTitle: string,
    type: FilteringType,
    columnNumber: number,
    options: Array<{ value: FilteringValueTypes, label: React.ReactNode }>,
    className: string,
    onExpandClick: (type: FilterExpansionType) => void
};

export const FilteringMenuMultiChoiceSection: React.FC<IProps> = (
    {
        expansion,
        onExpandClick,
        sectionTitle,
        type,
        columnNumber,
        options,
        className,
    }
) => {
    const { filteringValues, onFilteringBoxClick, resetFilterType } = useFiltering();

    return (
        <Accordion id={type} className={className} disableGutters
                   expanded={expansion} elevation={0} sx={{'&:before': {height: '0px'}}}>
            <AccordionSummary
                onClick={() => onExpandClick(type as FilterExpansionType)}
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box sx={(theme) => ({
                    display: "flex",
                    gap: theme.spacing(2),
                    alignItems: "center"
                })}>
                    <Typography sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(16),
                        fontWeight: theme.typography.fontWeightBold,
                    })}>{sectionTitle}</Typography>
                    {filteringValues[type].length > 0 &&
                        <Box
                            className={"filter-reset"}
                            sx={(theme) => ({
                                fontSize: theme.typography.body2,
                                borderStyle: "solid",
                                borderColor: "#B0B0B0",
                                paddingX: theme.spacing(1),
                                paddingY: theme.spacing(0),
                                borderRadius: theme.spacing(1),
                                '&:active': {
                                    backgroundColor: '#F7F7F7',
                                },
                            })}
                            onClick={(event) => {
                                event.stopPropagation();
                                resetFilterType(type)
                            }}
                        >
                            Reset
                        </Box>}
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={theme => ({
                paddingTop: theme.spacing(0),
            })}>
                <Grid container columns={columnNumber} columnSpacing={2} sx={theme => ({})}>
                    {options.map(option => (
                        <Grid id={option.value.toString()} item xs={2} md={1} sx={theme => ({
                            display: "flex",
                            minWidth: "100px"
                        })}>
                            <FormControlLabel sx={{
                                overflow: "hidden",
                                '.MuiFormControlLabel-label': {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    fontSize: 14,
                                    width: "100%",
                                },
                            }} control={
                                <Checkbox
                                    checked={filteringValues[type].includes(option.value)}
                                    sx={{
                                        margin: 0,
                                        '& .MuiSvgIcon-root': {fontSize: 24},
                                        color: "#717171",
                                        '&.Mui-checked': {
                                            color: "#717171",
                                        },
                                    }}
                                    onClick={() => onFilteringBoxClick(type, option.value)}
                                />
                            } label={option.label}/>
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
};