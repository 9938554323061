import React from "react";
import {SvgIcon} from "@mui/material";

export const ScheduledIcon: React.FC = () => {
    return (
        <SvgIcon fillOpacity={0} width="20" height="20" viewBox="0 0 20 20" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="7.25" stroke="#222222" strokeWidth="1.5"/>
        </SvgIcon>
    )
};