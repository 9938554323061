import React, {FC} from "react";
import {Box, Tooltip} from "@mui/material";
import {useGetTask} from "../../hooks/useGetTask";
import DobbyComponent from "../presentational/DobbyComponent";

type IProps = {
    id: string,
};

/**
 * **Responsibility**: TBC
 */
const TaskStatus: FC<IProps> = ({id}) => {
    const task = useGetTask(id);

    if (!task) return null;
    return (
        <Box>
            <Tooltip
                title={task.statusComment}
                placement={"bottom-start"}
            >
                <Box sx={{display: "flex", justifyContent: "center"}}>
                    <DobbyComponent
                        status={task.status}
                        highlighted={!!task.statusComment}
                        id={id}
                    />
                </Box>
            </Tooltip>
        </Box>
    );
};

export default TaskStatus;
