import React from "react";
import {SvgIcon} from "@mui/material";

export const ToggleOffIcon: React.FC = () => {
    return (
        <SvgIcon sx={{width: "50px", height: "32px"}} width="50" height="32" viewBox="0 0 50 32" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="32" rx="16" fill="#B0B0B0"/>
            <circle cx="16" cy="16" r="14" fill="white"/>
        </SvgIcon>
    )
};