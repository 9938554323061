import React from "react";
import {Menu as MuiMenu} from "@mui/material";

type IProps = {
    id: string | undefined;
    open: boolean,
    anchorEl?: Element,
    onClose: () => void,
    children: React.ReactNode,
};

export const Menu: React.FC<IProps> = ({id, open, onClose, anchorEl, children}) => {
    return (
        <MuiMenu
            id={id}
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            sx={theme => ({
                "& .MuiMenu-paper": {
                    borderRadius: theme.spacing(1),
                    marginTop: theme.spacing(1),
                },
                "& ul": {
                    padding: 0,
                }
            })}
        >
            {children}
        </MuiMenu>
    )
};