import React, {FC} from "react";
import HeaderComponent from "./HeaderComponent";
import Plan from "../domain/Plan";
import FooterComponent from "./FooterComponent";
import ContentWithHeaderFooterComponent from "./ContentWithHeaderFooterComponent";

/**
 * **Responsibility: Flex display of plan header, content and footer
 */
const ResponsivePlanComponent: FC = () => {
    return (
        <ContentWithHeaderFooterComponent
            header={<HeaderComponent/>}
            footer={<FooterComponent/>}
            content={<Plan/>}
        />
    );
};

export default ResponsivePlanComponent;
