import React, {FC, useRef, useState} from "react";
import {Box} from "@mui/material";
import {useGetTask} from "../../hooks/useGetTask";
import TaskName from "./TaskName";
import TaskStatus from "./TaskStatus";
import TaskOwner from "./TaskOwner";
import TaskLearnings from "./TaskLearnings";
import FlexGrowBox from "../presentational/FlexGrowBox";
import TaskHandle from "./TaskHandle";
import PortKey from "./PortKey";
import {TaskTimeline} from "./TaskTimeline";
import {TaskEditButton} from "../interactive/button/TaskEditButton";
import {TaskTimelineWrapper} from "../interactive/click/TaskTimelineWrapper";

type IProps = {
    id: string,
};

/**
 * **Responsibility**: TBC
 */
const Task: FC<IProps> = ({id}) => {
    const task = useGetTask(id);
    const ref = useRef<HTMLDivElement>(null);
    const [isHovered, setHoveredState] = useState<boolean>(false);

    if (!task) return null;

    return (
        <Box
            onMouseEnter={() => setHoveredState(true)}
            onMouseLeave={() => setHoveredState(false)}
            ref={ref}
            sx={{
                display: "flex",
                height: "100%",
                justifyContent: "space-between",
                alignItems: "stretch",
                position: "relative"
            }}
            className={`task-content-${id}`}>
            <FlexGrowBox alignedAtStart={true} factor={1} basis={"25%"}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "100%",
                }}>
                    <TaskHandle id={id} hovered={isHovered}/>
                    <Box sx={{flexGrow: 1, height: "100%"}}>
                        <TaskName id={id}/>
                    </Box>
                    <PortKey id={id}/>
                </Box>
            </FlexGrowBox>
            <FlexGrowBox centered={true} factor={1} basis={"5%"}>
                <TaskStatus id={id}/>
            </FlexGrowBox>
            <FlexGrowBox centered={true} factor={1} basis={"7%"}>
                <TaskOwner id={id}/>
            </FlexGrowBox>
            <FlexGrowBox factor={1} basis={"43%"}>
                <TaskTimelineWrapper id={id}>
                    <TaskTimeline id={id}/>
                </TaskTimelineWrapper>
            </FlexGrowBox>
            <FlexGrowBox alignedAtStart={true} factor={1} basis={"20%"}>
                <TaskLearnings id={id}/>
            </FlexGrowBox>
            <TaskEditButton id={id} hovered={isHovered}/>
        </Box>
    );
};

export default Task;
