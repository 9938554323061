export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// TODO: This was a bad, bad idea
export const getEverythingApiUrl = (id: string) =>
    `${API_BASE_URL}/plans/${id}`;

export const getPeopleApiUrl = () =>
    `${API_BASE_URL}/peoples`;

export const getProjectApiUrl = () =>
    `${API_BASE_URL}/projects`;

export const getGroupPMApiUrl = () =>
    `${API_BASE_URL}/projects/group-pms`;

export const getEditPlanApiUrl = (id: string) =>
    `${API_BASE_URL}/plans/${id}`;

export const getEditTaskApiUrl = (id: string) =>
    `${API_BASE_URL}/tasks/${id}`;

export const getRedorderTasksApiUrl = (id: string) =>
    `${API_BASE_URL}/plans/${id}/reorder-tasks`;

export const getCreatePlanApiUrl = () =>
    `${API_BASE_URL}/plans`;

export const getCreateTaskApiUrl = () =>
    `${API_BASE_URL}/tasks`;

export const getCreateMilestoneApiUrl = () =>
    `${API_BASE_URL}/milestones`;

export const getSiriusVeilApiUrl = () =>
    `${API_BASE_URL}/sirius-veils`;

export const getEditSiriusApiUrl = (id: string) =>
    `${API_BASE_URL}/sirius-veils/${id}`;

export const getDeletePageApiUrl = (id: string, objectType: "plans" | "projects" | "tasks" | "date-items" | "milestones" | "sirius-veils") =>
    `${API_BASE_URL}/${objectType}/${id}`;

export const getEditMilestoneApiUrl = (id: string) =>
    `${API_BASE_URL}/milestones/${id}`;

export const getMoveUpApiUrl = (id: string) =>
    `${API_BASE_URL}/tasks/${id}/up`;

export const getMoveDownApiUrl = (id: string) =>
    `${API_BASE_URL}/tasks/${id}/down`;

export const getArchiveTaskApiUrl = (id: string) =>
    `${API_BASE_URL}/tasks/${id}/archive`;
