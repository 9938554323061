import React from "react";
import {SvgIcon} from "@mui/material";

export const FilterIcon: React.FC = () => {
    return (
        <SvgIcon
            sx={{width: "14px", height: "14px"}}
            width="16" height="16" viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6667 1.3335H3.33337C2.80294 1.3335 2.29423 1.54421 1.91916 1.91928C1.54409 2.29436 1.33337 2.80306 1.33337 3.3335V4.1135C1.33328 4.38879 1.39002 4.66114 1.50004 4.9135V4.9535C1.59423 5.16748 1.72764 5.36194 1.89337 5.52683L6.00004 9.60683V14.0002C5.99981 14.1135 6.02846 14.2249 6.08329 14.3241C6.13811 14.4232 6.2173 14.5068 6.31337 14.5668C6.41947 14.6326 6.54189 14.6672 6.66671 14.6668C6.77107 14.6662 6.87383 14.6411 6.96671 14.5935L9.63337 13.2602C9.74332 13.2048 9.83577 13.12 9.90049 13.0153C9.96521 12.9105 9.99967 12.7899 10 12.6668V9.60683L14.08 5.52683C14.2458 5.36194 14.3792 5.16748 14.4734 4.9535V4.9135C14.5926 4.66312 14.6584 4.39068 14.6667 4.1135V3.3335C14.6667 2.80306 14.456 2.29436 14.0809 1.91928C13.7058 1.54421 13.1971 1.3335 12.6667 1.3335ZM8.86004 8.86016C8.79825 8.92246 8.74937 8.99633 8.71619 9.07756C8.68302 9.15878 8.6662 9.24576 8.66671 9.3335V12.2535L7.33337 12.9202V9.3335C7.33388 9.24576 7.31706 9.15878 7.28389 9.07756C7.25071 8.99633 7.20183 8.92246 7.14004 8.86016L3.60671 5.3335H12.3934L8.86004 8.86016ZM13.3334 4.00016H2.66671V3.3335C2.66671 3.15669 2.73695 2.98712 2.86197 2.86209C2.98699 2.73707 3.15656 2.66683 3.33337 2.66683H12.6667C12.8435 2.66683 13.0131 2.73707 13.1381 2.86209C13.2631 2.98712 13.3334 3.15669 13.3334 3.3335V4.00016Z"
                fill="#222222"/>
        </SvgIcon>
    )
};

