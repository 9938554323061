import {RefObject} from "react";

// TODO: Rename and refactor this
export const useReverseCalculation = (
    startRef: RefObject<HTMLDivElement | undefined>,
    scheduledRef: RefObject<HTMLDivElement | undefined>,
    delayedRef: RefObject<HTMLDivElement | undefined>
): boolean => {
    const middleBox = scheduledRef.current?.getBoundingClientRect();
    if (!middleBox) return false;
    const firstBox = startRef.current?.getBoundingClientRect();
    const lastBox = delayedRef.current?.getBoundingClientRect();
    if (firstBox && firstBox.right > middleBox.left) return true;
    if (!lastBox) return false;
    return middleBox.right > lastBox.left;
};
