import React from "react";
import {Box, TextField} from "@mui/material";
import {Text} from "../../presentational/Text";
import {SBox} from "../../presentational/SBox";
import {formatDate} from "../../../utils/dates";

type IProps = {
    value: Date | null,
    onChange: (date: Date | null) => void,
}

export const DateField: React.FC<IProps> = ({value, onChange}) => {
    return (
        <SBox flex spaceBetween fullWidth className={"date-field"}>
            <TextField
                type="date"
                value={formatDate(value)}
                onChange={e => onChange(!e.target.value ? null : new Date(`${e.target.value}`))}
                sx={{
                    "& input": {
                        padding: 0,
                    },
                    "& fieldset": {
                        border: "none",
                    }
                }}/>
            <Box onClick={() => {
                onChange(null);
            }}>
                <Text bold noDecoration>
                    Clear
                </Text>
            </Box>
        </SBox>
    )
};