import React, {useRef, useState} from "react";
import Box from "@mui/material/Box";
import {SBox} from "../../presentational/SBox";
import {Menu} from "./Menu";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sortingAtom, SortingType} from "../../../constants/atoms";
import {SortingMenuLine} from "../../presentational/SortingMenuLine";
import {SortIcon} from "../../icons/SortIcon";
import {Text} from "../../presentational/Text";

export const SortMenu: React.FC = () => {
    const ref = useRef();
    const [open, setOpen] = useState<boolean>(false);
    const sortingValues = useRecoilValue(sortingAtom);
    const setSorting = useSetRecoilState(sortingAtom);
    const onSortingBoxClick = (type: SortingType) => setSorting({
        ...sortingValues,
        [type]: !sortingValues[type],
    });
    return (
        <>
            <Box
                id={"sort-menu-button"} ref={ref} onClick={() => setOpen(true)}
            >
                <SBox
                    sx={theme => ({
                        gap:theme.spacing(0.5),
                        cursor: 'pointer',
                        backgroundColor: open ? "#f7f7f7" : "white",
                    })}
                    bordered
                    padding={[0.5, 1, 0.5, 1]}
                    margin={[0, 0, 0, 2]}
                    aligned>
                    <SortIcon/>
                    <Text buttonSecondaryMedium>
                        Sort
                    </Text>
                </SBox>
            </Box>
            <Menu
                id={"sort-menu"}
                open={open}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}>
                <SBox padding={[3, 3, 3, 3]}>
                    <SortingMenuLine
                        first
                        value={sortingValues.ANDON}
                        title="By andon"
                        subTitle="Display the andons at the top"
                        onClick={() => onSortingBoxClick("ANDON")}/>
                    <SortingMenuLine
                        value={sortingValues.PRIORITY}
                        title="By priority"
                        subTitle="Display the next release dates on top"
                        onClick={() => onSortingBoxClick("PRIORITY")}/>
                </SBox>
            </Menu>
        </>
    )
};