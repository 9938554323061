import React from "react";
import {WithProviders} from "./logical/WithProviders";
import {Routes} from "./logical/Routes";

export const App: React.FC = () => {
    return (
        <WithProviders>
            <Routes/>
        </WithProviders>
    )
};