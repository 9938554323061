import React, {FC} from "react";
import {Box, Divider} from "@mui/material";
import {usePlan} from "../../hooks/usePlan";
import {useMinMaxDates} from "../../hooks/useMinMaxDates";

type IProps = {};

/**
 * **Responsibility**: TBC
 */
// TODO: Refactor the position process of this to factorize it with other elements
const TodayBar: FC<IProps> = () => {
    const plan = usePlan();
    const [minDate, maxDate] = useMinMaxDates();

    if (!plan) return null;

    const allTimelineBoxes = document.getElementsByClassName("timeline-box");
    const headerBox = document.getElementById("header-bar");
    const firstTimelineBox = allTimelineBoxes[0] as HTMLDivElement;
    const todayDate = new Date(new Date().toISOString().slice(0, 10) + "T00:00:00.000Z");
    const dateItemTime = todayDate.getTime();
    const planMinDateTime = minDate.getTime();
    const planMaxDateTime = maxDate.getTime();
    if (dateItemTime < planMinDateTime || planMaxDateTime < dateItemTime) return null;
    const contentBox = document.getElementById("plan-content");
    const contentHeight = contentBox?.clientHeight;
    if (!contentHeight) return null;

    const marginLeftPercentage = (dateItemTime - planMinDateTime) / (planMaxDateTime - planMinDateTime);

    if (!firstTimelineBox) return null;
    if (!headerBox) return null;

    return (
        <Box sx={{
            position: "absolute",
            top: headerBox.offsetHeight,
            left: firstTimelineBox.offsetLeft + firstTimelineBox.clientWidth * marginLeftPercentage,
            height: contentHeight,
            transform: "translate(-1.5px)",
        }}>
            <Divider orientation={"vertical"} sx={{
                borderRightWidth: "medium",
                backgroundColor: "#1172BD",
                opacity: 0.7,
                height: "100%",
                position: "absolute",
            }}/>
        </Box>
    );
};

export default TodayBar;
