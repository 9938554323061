import {useMutation} from "react-query";
import {makeDeleteRequest} from "../utils/http";
import {getDeletePageApiUrl} from "../utils/urls";
import {queryClient} from "../utils/query-client";

export const useDeleteMilestone = (): (id: string) => void => {
    const mutation = useMutation({
        mutationFn: async (id: string) => {
            await makeDeleteRequest({
                url: getDeletePageApiUrl(id, "milestones"),
                body: {}
            })
        },
        onSuccess: () => queryClient.invalidateQueries({
            queryKey: ["data"]
        }),
    });
    return (id: string) => {
        mutation.mutate(id);
    };
};