import React from "react";
import DobbyComponent from "../components/presentational/DobbyComponent";
import {FilteringValueTypes} from "./atoms";

export const statusFilters: Array<{ value: FilteringValueTypes, label: React.ReactNode }> = [
    {value: "✅", label: <DobbyComponent status={"✅"} id={""} highlighted={false}/>},
    {value: "🟢", label: <DobbyComponent status={"🟢"} id={""} highlighted={false}/>,},
    {value: "🟠", label: <DobbyComponent status={"🟠"} id={""} highlighted={false}/>,},
    {value: "🔴", label: <DobbyComponent status={"🔴"} id={""} highlighted={false}/>,},
];


export const entityFilters: Array<{ value: FilteringValueTypes, label: React.ReactNode }> = [
    {value: "0", label: "Sipios"},
    {value: "1", label: "Theodo FR"},
    {value: "2", label: "Theodo UK"},
    {value: "3", label: "Theodo US"},
    {value: "4", label: "Nimbleways"},
    {value: "5", label: "Hokla"},
    {value: "6", label: "Solona"},
    {value: "7", label: "Aleios"},
    {value: "8", label: "Sicara"},
    {value: "9", label: "Padok"},
    {value: "10", label: "M33"},
    {value: "11", label: "BAM"},
];