import React, {FC} from "react";
import {Box} from "@mui/material";
import {PeoplePicture} from "./PeoplePicture";
import {Text} from "./Text";
import {usePlan} from "../../hooks/usePlan";

type IProps = {};

/**
 * **Responsibility**: Display Plan Owner Picture
 */
const PlanOwnerPicComponent: FC<IProps> = () => {
    const plan = usePlan();
    if (!plan) return null;
    const owner = plan.owner;
    if (!owner) {
        return (
            <Box
                bgcolor={"#dddddd"}
                sx={(theme) =>({
                    cursor: 'pointer',
                    borderRadius: theme.spacing(1),
                    paddingX: theme.spacing(1),
                    paddingY: theme.spacing(0.5),
                    backgroundColor: "#dddddd",
                    borderStyle: "solid",
                    borderColor: "#dddddd",
                })}
                id={"plan-empty-owner-text"}
            >
                <Text buttonSecondaryMedium>Owner</Text>
            </Box>
        )
    }
    return (
        <Box
            sx={(theme) =>({
                cursor: 'pointer',
            })}
            id={"plan-owner-pic"}
        >
            <PeoplePicture size={40} people={owner}/>
        </Box>
    );
};

export default PlanOwnerPicComponent;
